import Phaser from 'phaser';
import pink_bg_main from '../../assets/images/backgrounds/pink_background_main.png';
import pink_ground_bg_001 from '../../assets/images/backgrounds/pink_ground_bg_001.png';
import pink_ground_bg_002 from '../../assets/images/backgrounds/pink_ground_bg_002.png';
import city_bg_001 from '../../assets/images/backgrounds/city_bg_001.jpg';
import laser_pulse from '../../assets/images/player/laser_pulse.png';

//alternative plaent bgs
import planet_bg_001 from '../../assets/images/backgrounds/planet_bg_001.png';
import planet_bg_002 from '../../assets/images/backgrounds/planet_bg_002.png';
import planet_bg_static from '../../assets/images/backgrounds/planet_bg_static.png';

//volcano backgrounds
import volcano_bg_001 from '../../assets/images/backgrounds/volcano_bg_001.png';
import volcano_bg_002 from '../../assets/images/backgrounds/volcano_bg_002.png';
import volcano_bg_static from '../../assets/images/backgrounds/volcano_bg_static.png';


//audio
import backgroundMusic from '../../assets/audio/big-trance-by-kris-klavenes-161372.mp3';
import laser_blast from '../../assets/audio/laser_blast.mp3';
import coinSound from '../../assets/audio/coinSound.mp3';
import explosion from '../../assets/audio/explosion.mp3';

//player
import playerSprite from '../../assets/images/pink_run_002.png';
import pink001 from '../../assets/images/pink_001.png';
import pink002 from '../../assets/images/pink_002.png';
import pink003 from '../../assets/images/pink_003.png';
import pink004 from '../../assets/images/pink_004.png';
import pink005 from '../../assets/images/pink_005.png';
import pink006 from '../../assets/images/pink_006.png';
import drone_001 from '../../assets/images/drone_001.png';
import drone_002 from '../../assets/images/enemies/drone_002.png';
import ship_001 from '../../assets/images/ship_001.png';
import ship_002 from '../../assets/images/ship_002.png';
import cactus_002 from '../../assets/images/obstacles/cactus_002.png';
import robot_001 from '../../assets/images/enemies/robot_001.png';
import level_logo from '../../assets/images/gameAssets/level_image.png';

import pink_player_001 from '../../assets/images/player/pink_player_a001.png';
import pink_player_002 from '../../assets/images/player/pink_player_a002.png';
import pink_player_003 from '../../assets/images/player/pink_player_a003.png';

import pink_player_flash from '../../assets/images/player/pink_player_flash.png';



//pink numbers
import zero_pink from '../../assets/images/gameAssets/zero_pink.png';
import one_pink from '../../assets/images/gameAssets/one_pink.png';
import two_pink from '../../assets/images/gameAssets/two_pink.png';
import three_pink from '../../assets/images/gameAssets/three_pink.png';
import four_pink from '../../assets/images/gameAssets/four_pink.png';
import five_pink from '../../assets/images/gameAssets/five_pink.png';
import six_pink from '../../assets/images/gameAssets/six_pink.png';
import seven_pink from '../../assets/images/gameAssets/seven_pink.png';
import eight_pink from '../../assets/images/gameAssets/eight_pink.png';
import nine_pink from '../../assets/images/gameAssets/nine_pink.png';

import speedPanel from '../../assets/images/gameAssets/speedPanel.png';
import rightSpeedPanel from '../../assets/images/gameAssets/rightSpeedPanel.png';
import nextLevelButton from '../../assets/images/gameAssets/next_level_button.png';
import wall from '../../assets/images/obstacles/pillar_002.png';

import life_heart from '../../assets/images/powerUps/life_heart.png';

import dot from '../../assets/images/gameAssets/dot.png';

import planet_doom from '../../assets/images/chapters/planet_doom.png';

//level instructions
import {levelInstructions} from "../../components/LevelInstructions";

//enemies
import new_drone from '../../assets/images/enemies/new_drone.png';
import yellow_missile from '../../assets/images/enemies/yellow_missile.png';
import tank_2 from '../../assets/images/enemies/tank_2.png';
import pink_final_001 from '../../assets/images/player/pink_final_001.png';
import pink_final_run from '../../assets/images/player/pink_final_run.png';
import gold_coin from '../../assets/images/gameAssets/gold_coin.png';
import settings_icon from "../../assets/images/gameAssets/settings.png";
import Shop from "../../components/Shop";

export default class ThePlanetOfDoomScene extends Phaser.Scene {
    constructor() {
        super('PinkGameScene');
         this.gameWidth = window.innerWidth * 0.75;
        this.gameHeight = 600;
        this.isMusicPlaying = true;
         this.enemySpawnTime = 6000;
        this.spawnEnemy = this.spawnEnemy.bind(this);
        this.centerX = window.innerWidth / 2;

        this.baseScrollSpeed = 0;     // Starting scroll speed
        this.maxScrollSpeed = 100;     // Maximum scroll speed
        this.scrollSpeed = 2;         // Current scroll speed
        this.acceleration = 0.05;      // How much speed increases per frame
        this.deceleration = 0.2;     // How quickly speed decreases per frame
        this.playerSpeed = 160;       // Player movement speed
        this.isGameOver = false;
        this.isJumping = false;
        this.canJump = true;

        //for mobile devices
        this.mobileControls = null;

        //for levels
        this.level = 0;
        this.levelBackgrounds = ['pink_ground_bg_001','pink_ground_bg_002','pink_ground_bg_001'];
        this.GROUND_Y= 550;

        this.numberArray = [
            'zero_pink',
            'one_pink',
            'two_pink',
            'three_pink',
            'four_pink',
            'five_pink',
            'six_pink',
            'seven_pink',
            'eight_pink',
            'nine_pink'
        ];

        this.levelTargetSpeedArray =
            [
                100, // level 0 - doesn't have a target
                20, // level 1
                30, // level 2
                30, // level 3
                50, // level 4
                50, // level 5
                50, // level 6
                50, // level 7
                50, // level 8
                70, // level 9
                20, // level 10
            ];

        this.gameStarted = false;

        //for animations
        // Add these to where you initialize your player
        this.animationFrame = 0;
        this.frameCounter = 0;
        this.animationSpeed = 10; // Adjust this number to control animation speed (higher = slower)

        //lasers
        this.laserGroup = null;
        this.enemies = null;
        this.obstacles = null;
        this.laserActive = false;

        //for collisions
        this.isInvulnerable = false;
        this.invulnerabilityDuration = 1000; // 1 second of invulnerability

        // Add a property to store colliders
        this.colliders = [];

        this.activeInstructions = [];
        this.hasPlayerMoved = false;
    }

    //sdk method called by phaser
    preload() {
        this.load.image('drone', drone_001);
        this.load.image('drone_002', drone_002);
        this.load.image('ship', ship_001);
        this.load.image('ship2', ship_002);
        this.load.image('levelLogo', level_logo);

        this.load.image('pink_bg_main',pink_bg_main);
        this.load.image('city_bg_001', city_bg_001);

        this.load.image('pink_ground_bg_001', pink_ground_bg_001);
        this.load.image('pink_ground_bg_002', pink_ground_bg_002);
        this.load.image('planet_bg_001',planet_bg_001);
        this.load.image('planet_bg_002', planet_bg_002);
        this.load.image('planet_bg_static', planet_bg_static);
        this.load.image('volcano_bg_001',volcano_bg_001);
        this.load.image('volcano_bg_002', volcano_bg_002);
        this.load.image('volcano_bg_static', volcano_bg_static);
        this.load.image('robot_001', robot_001);

        // Load the audio using the imported file
        this.load.audio('background-music', backgroundMusic);
        this.load.audio('laser_blast', laser_blast);
        this.load.audio('coinSound', coinSound);
        this.load.audio('explosion', explosion);

        // Load all frames
        this.load.image('pink1', pink001);
        this.load.image('pink2', pink002);
        this.load.image('pink3', pink003);
        this.load.image('pink4', pink004);
        this.load.image('pink5', pink005);
        this.load.image('pink6', pink006);

        this.load.image('zero_pink', zero_pink);
        this.load.image('one_pink', one_pink);
        this.load.image('two_pink', two_pink);
        this.load.image('three_pink', three_pink);
        this.load.image('four_pink', four_pink);
        this.load.image('five_pink', five_pink);
        this.load.image('six_pink', six_pink);
        this.load.image('seven_pink', seven_pink);
        this.load.image('eight_pink', eight_pink);
        this.load.image('nine_pink', nine_pink);
        this.load.image('speedPanel',speedPanel);
        this.load.image('rightSpeedPanel',rightSpeedPanel);
        this.load.image('nextLevelButton',nextLevelButton);

        //obstactles
        this.load.image('wall',wall);

        //enemies
        this.load.image('new_drone', new_drone);

        // Load WebFont
        this.load.script('webfont', 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js');

        //life and power-ups
        this.load.image('life_heart',life_heart);
        this.load.image('laser_pulse',laser_pulse);
        this.load.image('dot', dot);

        this.load.image('planet_doom',planet_doom);

        this.load.image('pink_final_001',pink_final_001);
        this.load.image('pink_final_run',pink_final_run);
        this.load.image('yellow_missile',yellow_missile);
        this.load.image( 'tank_2', tank_2);
        this.load.image('settings_icons',settings_icon);
        this.load.image('gold_coin',gold_coin);

    }

    createBackgrounds(level){

        let staticBackgroundImage = "city_bg_001";
        if (this.level === 6){
            this.levelBackgrounds = ['planet_bg_001','planet_bg_002','planet_bg_001'];
            staticBackgroundImage = "planet_bg_static";
        } else if (this.level === 7){
            this.levelBackgrounds = ['volcano_bg_001','volcano_bg_002','volcano_bg_001'];
            staticBackgroundImage = "volcano_bg_static";
        }

        // Create three background sprites
        this.background1 = this.add.sprite(0, 0, this.levelBackgrounds[0]);
        this.background2 = this.add.sprite(0, 0, this.levelBackgrounds[1]);
        this.background3 = this.add.sprite(0, 0, this.levelBackgrounds[2]);
       // this.background4 = this.add.sprite(0, 0, this.levelBackgrounds[0]);

        // Set the origin to top-left corner
        this.background1.setOrigin(0, 0);
        this.background2.setOrigin(0, 0);
        this.background3.setOrigin(0, 0);
       // this.background4.setOrigin(0, 0);

        // Scale backgrounds to fit game height
        const scaleY = this.gameHeight / this.background1.height;
        this.background1.setScale(scaleY);
        this.background2.setScale(scaleY);
        this.background3.setScale(scaleY);
       // this.background4.setScale(scaleY);

        // Calculate the scaled width
        this.bgWidth = Math.ceil(this.background1.width * scaleY);

        // Initial positioning
        this.background1.x = 0;
        this.background2.x = this.bgWidth;
        this.background3.x = this.bgWidth * 2;
       // this.background4.x = this.bgWidth * 3;

        // Store the backgrounds in an array
        this.backgrounds = [this.background1, this.background2, this.background3 ];

        //now add the static background
        // In your create() function, add this FIRST (before other images)
        // This ensures it's rendered behind everything else
        if (this.level > 4){
            this.bg = this.add.image(0, 0, staticBackgroundImage);
            this.bg.setOrigin(0, 0); // Align to top-left corner
            this.bg.setScrollFactor(0); // This makes it stay static/fixed

            // Optional: Scale the background to fit the game width/height
            this.bg.setDisplaySize(this.cameras.main.width, this.cameras.main.height);
            this.bg.setDepth(-1); // Puts it behind elements with higher depth values
        }

    }

    // Add this method to your scene
    updateBackgrounds() {
        // Move each background based on scroll speed
        if(this.areThereBackgroundsOnThisLevel) {
            this.backgrounds.forEach((bg, index) => {
                // Move the background left based on scroll speed
                bg.x -= this.scrollSpeed; // * this.scrollSpeeds[`background${index + 1}`];

                // Check if the background has moved completely off screen to the left
                if (bg.x <= -this.bgWidth) {
                    // Find the rightmost background
                    let rightmostX = -Infinity;
                    this.backgrounds.forEach(otherBg => {
                        rightmostX = Math.max(rightmostX, otherBg.x);
                    });

                    // Place this background to the right of the rightmost one
                    bg.x = rightmostX + this.bgWidth;
                }
            });
        }
    }

    addCoins(numberOfCoins) {
        const GROUND_LEVEL = 500;
        const MIN_SPACING = 400;
        const MAX_SPACING = 2000;
        const MIN_HEIGHT = GROUND_LEVEL - 300;
        const MAX_HEIGHT = GROUND_LEVEL - 100;

        let currentX = this.gameWidth + 1000;

        if (!this.coins) {
            this.coins = [];
        }

        for (let i = 0; i < numberOfCoins; i++) {
            const randomSpacing = Phaser.Math.Between(MIN_SPACING, MAX_SPACING);
            const randomY = Phaser.Math.Between(MIN_HEIGHT, MAX_HEIGHT);

            // Create sprite first
            const coin = this.add.sprite(currentX, randomY, 'gold_coin');
            coin.setScale(0.3);
            coin.setDepth(1000);

            // Add physics to the sprite
            this.physics.add.existing(coin, false);

            if (coin.body) {
                coin.body.setAllowGravity(false);
                coin.body.setVelocityX(-200);
            }

            // Add floating animation
            this.tweens.add({
                targets: coin,
                y: randomY + 20,
                duration: 1500,
                yoyo: true,
                repeat: -1,
                ease: 'Sine.easeInOut'
            });

            // Add collision with player
            this.physics.add.overlap(
                this.player,
                coin,
                this.collectCoin,
                null,
                this
            );

            this.coins.push(coin);
            currentX += randomSpacing;
        }
    }


    collectCoin(player, coin) {
        // Play sound
        if (this.coinSound) {
            this.coinSound.play({
                volume: 0.5
            });
        }

        // Remove from array
        this.coins = this.coins.filter(c => c !== coin);

        // Destroy the coin sprite
        coin.destroy();

        // Update score
        if (!this.coinsCollected) {
            this.coinsCollected = 0;
        }
        this.coinsCollected++;

        // Update local storage
        const gameData = JSON.parse(localStorage.getItem('gameData') || '{}');
        gameData.totalCoins = (gameData.totalCoins || 0) + 1;
        localStorage.setItem('gameData', JSON.stringify(gameData));
    }

    updateCoins() {
        if (this.coins && this.coins.length > 0) {
            this.coins.forEach(coin => {
                if (coin.active && coin.body) {
                    // Update speed based on scroll speed
                    const speedMultiplier = 0.5;
                    const baseSpeed = -200;
                    const newVelocity = baseSpeed + (this.scrollSpeed * speedMultiplier);
                    coin.body.setVelocityX(newVelocity);

                    // Remove coins that are off screen
                    if (coin.x < -100) {
                        this.coins = this.coins.filter(c => c !== coin);
                        coin.destroy();
                    }
                }
            });
        }
    }


    createCoins(){
        // Add coin sound
        this.coinSound = this.sound.add('coinSound');  // Make sure to preload this

        // Initialize coins collected
        this.coinsCollected = 0;

        // Add initial coins
        this.addCoins(20);  // Adjust number as needed
    }


    createStarfield() {
        // Create a container for stars
        this.stars = [];
        const numStars = 300; // Adjust number of stars

        for (let i = 0; i < numStars; i++) {
            // Create star as a small circle
            const star = this.add.circle(
                Phaser.Math.Between(0, this.game.config.width * 2), // Random x position
                Phaser.Math.Between(0, this.game.config.height),    // Random y position
                Phaser.Math.Between(1, 2),  // Random size between 1-2 pixels
                0xFFFFFF,    // White color
                Phaser.Math.Between(0.3, 1) // Random alpha for twinkling effect
            );

            // Set parallax scroll factor (less than 1 for slower movement)
            star.setScrollFactor(0.3); // Adjust this value to change star movement speed
            star.setDepth(-0.5); // Between city background and moving backgrounds
// Add speed property to each star
            star.speed = 0.3; // This will make stars move at 30% of background speed


            // Add optional twinkling effect
            this.tweens.add({
                targets: star,
                alpha: 0.2,
                duration: Phaser.Math.Between(1000, 3000),
                yoyo: true,
                repeat: -1,
                ease: 'Sine.easeInOut'
            });

            this.stars.push(star);
        }

        // Optional: Add different colored stars
        const starColors = [0xFFFFFF, 0xFFE4E1, 0xFFB6C1, 0xE6E6FA];
        this.stars.forEach(star => {
            star.setFillStyle(
                Phaser.Utils.Array.GetRandom(starColors),
                Phaser.Math.Between(0.3, 1)
            );
        });
    }

    initializePhysicsGroups() {
        console.log('Initializing physics groups');

        try {
            // Only initialize groups if they don't exist or are inactive
            if (!this.laserGroup?.active) {
                this.laserGroup = this.physics.add.group({
                    allowGravity: false,
                    immovable: true
                });
            }

            if (!this.enemies?.active) {
                this.enemies = this.physics.add.group({
                    allowGravity: false
                });
            }

            if (!this.obstacles?.active) {
                this.obstacles = this.physics.add.group({
                    allowGravity: false,
                    immovable: true
                });
            }

            // Handle collider setup more carefully
            if (this.physics && (!this.laserEnemyCollider?.active)) {
                console.log(" if (this.physics && (!this.laserEnemyCollider?.active)) {");
                // Only create new collider if it doesn't exist or is inactive
                this.laserEnemyCollider = this.physics.add.collider(
                    this.laserGroup,
                    this.enemies,
                    this.handleLaserEnemyCollision,
                    (laser, enemy) => {
                        // Simplified process callback
                        return laser && enemy;
                    },
                    this
                );
            }

            console.log('Physics groups initialized successfully');
        } catch (error) {
            console.error('Error in initializePhysicsGroups:', error);
        }
    }

// Add this method to safely clear groups without destroying them
    clearGroups() {
        console.log("clearGroups called");
        try{
            if (this.laserGroup?.clear) {
                this.laserGroup.clear(true, true);
            }
            if (this.enemies?.clear) {
                this.enemies.clear(true, true);
            }
            if (this.obstacles?.clear) {
                this.obstacles.clear(true, true);
            }
        }catch(e){
            console.log("clearGroups error: " + e );
        }

    }
    createEnemies(){
        // Just create the physics group
        console.log("enemies called");
        this.enemies = this.physics.add.group();

        /*// Set up laser collisions with the physics group
        this.physics.add.collider(
            this.laserGroup,
            this.enemies,
            this.handleLaserEnemyCollision,
            null,
            this
        );*/

        this.time.addEvent({
            delay: this.enemySpawnTime,
            callback: this.addDrone,
            callbackScope: this,
            loop: true
        });
    }

    createLasers(){

        //Initialize input properly
        this.keys = {
            space: this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE)
        };
        // Initialize laser group
        this.laserGroup = this.physics.add.group({
            allowGravity: false,
            immovable: true,
            bounceX: 0,
            bounceY: 0,
            dragX: 0,
            dragY: 0
        });

        // Create the sound instance
        this.laserSound = this.sound.add('laser_blast');

    }

    updateLasers(){
        // Add safety checks in update
        if (!this.physics || !this.laserGroup) {
            console.warn('Physics or laser group missing in update');
            return;
        }

        // Check if laser is off screen
        if (this.laserActive) {
            const laser = this.laserGroup.getChildren()[0];
            if (laser && laser.x > this.cameras.main.scrollX + this.game.config.width + 100) {
                laser.destroy();
                this.laserActive = false;
            }
        }
        // Check for laser fire - use isDown instead of JustDown
        try{
            if (this.keys.space.isDown && !this.laserActive) {
                this.fireLaser();
            }
        }catch (error){
            console.log("Error with firing laser: " + error);
        }

        // Check if any active lasers are off screen
        //this.checkLaserBounds();
    }

    fireLaser() {
        console.log("fireLaser called");
        if (this.laserActive) return;
        const gameData = JSON.parse(localStorage.getItem('gameData') || '{}');
        const purchases = gameData.purchases || {};
        const hasLaserBlast = purchases.laser_pulse === true;
        const hasDoubleLaser = purchases.double_laser_beam === true;

        console.log("gameData : " + JSON.stringify(gameData));
        console.log("hasLaserBlast: " + hasLaserBlast);
        console.log("hasDoubleLaser: " + hasDoubleLaser);

        if (hasLaserBlast) {
            const createLaser = () => {

                // Play the laser sound
                this.laserSound.play({
                    volume: 0.5,  // Adjust volume (0-1)
                    rate: 1.0     // Playback rate (1 is normal speed)
                });

                // Additional validation before creating laser
                if (!this.laserGroup.scene) {
                    console.log('Laser group has no scene reference, reinitializing...');
                    this.laserGroup = this.physics.add.group();
                }

                try {
                    const laser = this.laserGroup.create(
                        this.player.x + 50,
                        this.player.y,
                        'laser_pulse'
                    );

                    if (!laser) {
                        console.warn('Failed to create laser');
                        return;
                    }

                    laser.setScale(0.5);

                    const baseSpeed = 800;
                    const acceleration = 1.5;
                    const playerSpeed = this.scrollSpeed || 0;
                    const laserSpeed = Math.max(baseSpeed, playerSpeed * acceleration);

                    laser.setVelocityX(laserSpeed);
                    laser.setVelocityY(0);
                    laser.body.setAllowGravity(false);
                    laser.body.setImmovable(true);

                    this.laserActive = true;


                    // Auto-cleanup of laser after certain distance/time
                    this.time.delayedCall(2000, () => {
                        if (laser?.active) {
                            laser.destroy();
                            this.laserActive = false;
                        }
                    });

                } catch (error) {
                    console.error('Error in fireLaser:', error);
                    this.laserActive = false;
                    this.initializePhysicsGroups();
                }

                try {
                    if (this.enemies && (this.enemies.getChildren().length > 0)) {
                        // Now we can safely set up collisions
                        this.physics.add.collider(
                            this.laserGroup,
                            this.enemies,
                            this.handleLaserEnemyCollision,
                            () => this.laserGroup.getChildren().length > 0 && this.enemies.getChildren().length > 0, // Only process if both groups have children
                            this
                        );
                    } else {
                        console.log("fireLaser(): NOT setting up laser enemy collision handling")
                    }
                } catch (error) {
                    console.log("fireLaser(): Error with setting up laser enemy collision: " + error);
                }

                try {
                    if (this.enemies && (this.enemies.getChildren().length > 0)) {
                        // Now we can safely set up collisions
                        this.physics.add.collider(
                            this.laserGroup,
                            this.enemies,
                            this.handleLaserObstacleCollision,
                            () => this.laserGroup.getChildren().length > 0 && this.obstacles.getChildren().length > 0, // Only process if both groups have children
                            this
                        );
                    } else {
                        console.log("fireLaser(): NOT setting up laser obstacles collision handling")
                    }
                } catch (error) {
                    console.log("fireLaser(): Error with setting up laser obstacles collision: " + error);
                }

                try {
                    if (this.robots) {
                        // Now we can safely set up collisions
                        this.physics.add.collider(
                            this.laserGroup,
                            this.robots,
                            this.handleLaserEnemyCollision,
                            () => this.laserGroup.getChildren().length > 0 && this.robots, // Only process if both groups have children
                            this
                        );
                    } else {
                        console.log("fireLaser(): NOT setting up laser robot collision handling")
                    }
                } catch (error) {
                    console.log("fireLaser(): Error with setting up laser robot collision: " + error);
                }

                try {
                    if (this.tanks) {
                        // Now we can safely set up collisions
                        this.physics.add.collider(
                            this.laserGroup,
                            this.tanks,
                            this.handleLaserTankCollision,
                            () => this.laserGroup.getChildren().length > 0 && this.tanks, // Only process if both groups have children
                            this
                        );
                    } else {
                        console.log("fireLaser(): NOT setting up laser tank collision handling")
                    }
                } catch (error) {
                    console.log("fireLaser(): Error with setting up laser tank collision: " + error);
                }
            }
            // Fire first laser
            createLaser();

            // If double laser is purchased, fire second laser after delay
            if (hasDoubleLaser) {
                this.time.delayedCall(150, () => {
                    createLaser();
                });
            }

        }
    }



    checkLaserBounds() {
        try {
            this.laserGroup.getChildren().forEach(laser => {
                if (laser.x > this.cameras.main.scrollX + this.game.config.width + 100) {
                    laser.destroy();
                    this.laserActive = false;
                }
            });
        }catch (error ) {
            console.log("Error checking laser bounds: " + error);
        }
    }

    handleLaserObstacleCollision(laser, obstacle) {
        // Remove only the laser when hitting an obstacle
        laser.destroy();
        this.laserActive = false;
    }

    handleLaserEnemyCollision(laser, enemy) {
        // Log positions for debugging
        console.log('Enemy position at collision:', enemy.x, enemy.y);

        // Play explosion sound
        this.sound.play('explosion', {
            volume: 0.5,    // Adjust volume (0-1)
            rate: 1.0       // Playback rate (1 is normal speed)
        });

        // Create explosion BEFORE destroying the enemy
        this.createExplosion(enemy.x, enemy.y);

        // Then destroy objects
        laser.destroy();
        enemy.destroy();
        this.laserActive = false;
    }

    handleLaserTankCollision(laser, enemy) {
        // Log positions for debugging
        console.log('Enemy position at collision:', enemy.x, enemy.y);

        // Play explosion sound
        this.sound.play('explosion', {
            volume: 0.5,    // Adjust volume (0-1)
            rate: 1.0       // Playback rate (1 is normal speed)
        });

        // Create explosion BEFORE destroying the enemy
        this.createExplosion(enemy.x, enemy.y);

        // Then destroy objects
        laser.destroy();
        enemy.destroy();
        this.laserActive = false;
    }

    createExplosion(x, y) {
        // Create a multi-layered explosion effect
        const particles = this.add.particles(x, y, 'laser_pulse', {
            speed: { min: -800, max: 800 },
            angle: { min: 0, max: 360 },
            scale: { start: 0.6, end: 0 },
            blendMode: 'ADD',
            lifespan: 300,
            quantity: 20,
            gravityY: 0,
            emitting: false,
            tint: [0xff8800, 0xff4400] // Orange to red gradient
        });

        // Add a second particle system for added effect
        const glowParticles = this.add.particles(x, y, 'laser_pulse', {
            speed: { min: -200, max: 200 },
            angle: { min: 0, max: 360 },
            scale: { start: 0.4, end: 0 },
            blendMode: 'ADD',
            lifespan: 400,
            quantity: 10,
            gravityY: 0,
            emitting: false,
            tint: 0xffff00 // Yellow glow
        });

        // Emit both particle systems
        particles.explode(20);
        glowParticles.explode(10);

        // Clean up both systems
        this.time.delayedCall(400, () => {
            particles.destroy();
            glowParticles.destroy();
        });
    }




    // Add this to your update function to handle stars that go off screen
    updateStars() {
        const gameWidth = this.game.config.width;
        //const scrollSpeed = this.currentSpeed || 0; // Use your game's current scroll speed

        if(this.areThereStarsOnThisLevel) {
            try {
                this.stars.forEach(star => {
                    // Move star based on scroll speed and its individual speed factor
                    star.x -= this.scrollSpeed * star.speed;

                    // If star moves off screen to the left, wrap it to the right
                    if (star.x < -50) {
                        star.x = gameWidth * 2;
                    }
                });
            } catch (e) {
                console.log("update star error: " + e);
            }
        }

    }

    createMusic(){
        // Add the music button in the top-right corner
        const musicButton = this.add.text(1750, 20, '🔊', {
            fontSize: '32px',
            padding: { x: 10, y: 5 },
            backgroundColor: '#000000',
            color: '#ffffff'
        })
            .setOrigin(1, 0)
            .setInteractive({ useHandCursor: true })
            .setScrollFactor(0); // This keeps it fixed on screen

        // Play the background music
        this.backgroundMusic = this.sound.add('background-music', {
            volume: 0.2 ,
            loop: true
        });
        this.backgroundMusic.play();

        // Add click handler for the music button
        musicButton.on('pointerdown', () => {
            if (this.isMusicPlaying) {
                this.backgroundMusic.pause();
                musicButton.setText('🔈');
            } else {
                this.backgroundMusic.resume();
                musicButton.setText('🔊');
            }
            this.isMusicPlaying = !this.isMusicPlaying;
        });

        // Optional hover effects
        musicButton.on('pointerover', () => {
            musicButton.setStyle({ backgroundColor: '#333333' });
        });

        musicButton.on('pointerout', () => {
            musicButton.setStyle({ backgroundColor: '#000000' });
        });
    }

    createGround(){
        this.ground = this.add.rectangle(
            this.game.config.width / 2,  // x position - center of screen
            550,                         // y position
            this.game.config.width,      // full width of game screen
            10,                          // height
            0x00ff00                    // color
        );
        // Make it invisible but keep collision functionality
        this.ground.setAlpha(0);

        this.physics.add.existing(this.ground, true);
    }

    createLevelDisplay() {
        // Clean up previous level display if it exists
        if (this.levelNumbers) {
            this.levelNumbers.forEach(number => number.destroy());
        }
        if (this.levelText) {
            this.levelText.destroy();
        }

        // Add level logo to top left
        const levelLogo = this.add.image(20, 10, 'planet_doom');
        levelLogo.setOrigin(0, 0);
        levelLogo.setScale(0.2);

        // Calculate position for text (centered under the logo)
        const textX = 20 + (levelLogo.width * levelLogo.scaleX) / 2;
        const textY = 10 + (levelLogo.height * levelLogo.scaleY) + 5; // 5px padding below logo

        // Add level text
        this.levelText = this.add.text(textX, textY, `LEVEL ${this.level}`, {
            fontFamily: 'Arial',
            fontSize: '16px',
            color: '#ffffff',
            align: 'center'
        });
        this.levelText.setOrigin(0.5, 0); // Center text horizontally
        this.levelText.setDepth(1000);
    }




    // Create the player sprite
    createPlayer(){

        this.player = this.physics.add.sprite(190, 530, 'pink_final_001');
        this.player.setScale(0.3);
        this.playerSpeed = 160;

        // Track animation state
        this.isFlying = false;

        // Enable physics on the player
        // Make sure you have these lines as well:
        this.physics.add.collider(this.player, this.ground);
        this.player.setCollideWorldBounds(true);
        this.player.body.setBoundsRectangle(new Phaser.Geom.Rectangle(
            0,          // x
            0,          // y
            this.game.config.width,  // width
            this.GROUND_Y    // height - stops at ground level
        ));

        this.player.body.setSize(this.player.width, this.player.height);

        // Enable physics for player if not already done
        this.physics.add.existing(this.player);
        this.player.body.setMass(2);
        this.player.body.setBounce(0);
        // Flying physics setup
        this.player.body.setGravityY(400);

        const createGlow = () => {
            const radius = 30; // Adjust this value to make the glow smaller/larger
            const graphics = this.add.graphics();

            // Create multiple circles with decreasing alpha for a softer glow
            const createGradientGlow = () => {
                graphics.clear();

                // Outer glow (very faint)
                graphics.fillStyle(0xffb6c1, 0.1);
                graphics.fillCircle(this.player.x, this.player.y, radius);

                // Middle glow
                graphics.fillStyle(0xffb6c1, 0.07);
                graphics.fillCircle(this.player.x, this.player.y, radius * 0.7);

                // Inner glow (brightest)
                graphics.fillStyle(0xffb6c1, 0.05);
                graphics.fillCircle(this.player.x, this.player.y, radius * 0.5);
            };

            createGradientGlow();
            graphics.setDepth(1002);
            graphics.setBlendMode(Phaser.BlendModes.ADD);

            return graphics;
        };

        this.glow = createGlow();

        this.isFlying = false;

        this.player.setDepth(1003);

       // this.createBlurEffect();

    }

    createKeyboard(){
        // Set up cursor keys
        this.cursors = this.input.keyboard.createCursorKeys();

        // In your cursor key handling code, play the animation when moving right
        if (this.cursors.right.isDown) {
            this.player.play('fly-right', true);  // true means play even if already playing
            this.player.setTexture('pink_player_flash');
        } else {
            this.player.stop();  // Stop the animation when not moving
            this.player.setTexture('pink_final_001');  // Reset to standing frame
        }
    }

    createSpeedBar() {
        const targetSpeed = this.levelTargetSpeedArray[this.level];
        const gameWidth = this.game.config.width;
        const padding = 15;


        // Add the right speed panel background image
        this.rightSpeedPanelBg = this.add.image(
            (gameWidth / 2) , // Center horizontally
            padding,  // Position from top with padding
            'rightSpeedPanel'
        );
        this.rightSpeedPanelBg.setOrigin(0.5, 0); // Set origin to top-center
        this.rightSpeedPanelBg.setScale(0.4,0.5); // Adjust scale as needed

        // Calculate the actual width of the panel image after scaling
        const panelWidth = this.rightSpeedPanelBg.width * this.rightSpeedPanelBg.scaleX;

        // Bar dimensions
        const sidePadding = 40; // Padding from panel edges
        const barWidth = panelWidth - (sidePadding * 2); // Panel width minus padding on both sides
        const barHeight = 15;

        // Calculate bar position relative to the panel image
        const barX = (gameWidth / 2) - (barWidth / 2); // Center the bar
        const barY = padding + 30; // Adjust this value to position the bar within the panel

        // Create background bar with beveled effect
        this.speedBg = this.add.graphics();
        this.speedBg.fillStyle(0xFFFFFF);
        this.speedBg.fillRect(barX, barY, barWidth, barHeight);
        this.speedBg.lineStyle(1, 0x444444);
        this.speedBg.strokeRect(barX, barY, barWidth, barHeight);

        // Create the fill bar with gradient effect
        this.speedFill = this.add.graphics();
        this.speedFill.fillStyle(0xFFD000);

        const targetX = barX + (barWidth * (targetSpeed / 100));

        if (this.level > 0) {
            // Add target speed marker with neon effect
            this.targetMarker = this.add.graphics();

            // Add glow effect to target marker
            this.targetMarker.lineStyle(4, 0xFF0000, 0.3);
            this.targetMarker.lineBetween(targetX, barY - 5, targetX, barY + barHeight);
            this.targetMarker.lineStyle(2, 0x000000, 1);
            this.targetMarker.lineBetween(targetX, barY - 5, targetX, barY + barHeight + 5);

            // Add target speed text with retro style
            this.targetText = this.add.text(targetX - 25, barY + barHeight + 5, `END: ${targetSpeed}`, {
                fontSize: '18px',
                fontFamily: 'Arial',
                fill: '#FFFFFF',
                stroke: '#330000',
                strokeThickness: 1
            }).setOrigin(0.5, 0);
            this.targetText.setDepth(1000);
            this.targetMarker.setDepth(1000);
        }

        const speedPanelX = barX - 150;
        const speedPanelY = 15;

        // Add the left speed panel background image
        this.speedPanelBg = this.add.image(speedPanelX, speedPanelY, 'speedPanel');
        this.speedPanelBg.setOrigin(0, 0);
        this.speedPanelBg.setScale(0.5,0.5);

        // Create speed text
        this.speedTextBar = this.add.text(
            speedPanelX + (this.speedPanelBg.width / 2) - 55,
            speedPanelY + (this.speedPanelBg.height / 2) - 50,
            '0',
            {
                fontSize: '24px',
                fontFamily: 'Arial',
                fill: '#FFFFFF',
                stroke: '#003300',
                strokeThickness: 2
            }
        ).setOrigin(0.5, 0.5);


        // Set proper depth for all elements
        this.rightSpeedPanelBg.setDepth(997);
        this.speedPanelBg.setDepth(997);
        this.speedBg.setDepth(998);
        this.speedFill.setDepth(999);

        this.speedTextBar.setDepth(1000);


        // Store the bar dimensions and target speed
        this.barDimensions = {
            x: barX,
            y: barY,
            width: barWidth,
            height: barHeight
        };
        this.targetSpeed = targetSpeed;


if (this.level > 3) {
// Add target speed marker with neon effect
    this.targetMarker = this.add.graphics();
    const targetXfly = barX + (barWidth * (targetSpeed / 100));

    // Add glow effect to target marker
    this.targetMarker.lineStyle(4, 0xFF0000, 0.3);
    this.targetMarker.lineBetween(targetX, barY - 5, targetXfly, barY + barHeight);
    this.targetMarker.lineStyle(2, 0x000000, 1);
    this.targetMarker.lineBetween(targetX, barY - 5, targetXfly, barY + barHeight + 5);

    // Add "FLY" marker at speed 30
    const flyX = barX + (barWidth * (30 / 100));
    this.flyMarker = this.add.graphics();
    this.flyMarker.lineStyle(4, 0x00FF00, 0.3); // Green glow effect
    this.flyMarker.lineBetween(flyX, barY - 5, flyX, barY + barHeight);
    this.flyMarker.lineStyle(2, 0x000000, 1);
    this.flyMarker.lineBetween(flyX, barY - 5, flyX, barY + barHeight + 5);

    // Add "FLY" text with retro style
    this.flyText = this.add.text(flyX - 20, barY + barHeight + 5, 'FLY: 30', {
        fontSize: '18px',
        fontFamily: 'Arial',
        fill: '#FFFFFF',
        stroke: '#003300',
        strokeThickness: 1
    }).setOrigin(0.5, 0);
    this.flyText.setDepth(1000);
    this.flyMarker.setDepth(1000);
}

// Add depth setting for new elements

    }

    createLives(){
        // In your create() function
        if (!this.registry.has('lives')) {
            this.registry.set('lives', 3); // Initial setup
        }
        // In your create() function, after creating the speedBar
        // Assuming speedBar is positioned at x, y coordinates

        // Create a group for the hearts
        this.hearts = this.add.group();

        // Position for the first heart (adjust these values based on your speedBar position)
        const startX = this.speedPanelBg.x + 830; // 20 pixels gap from speedBar
        const heartY = this.speedPanelBg.y + 50; // Same y-position as speedBar

        // Create hearts based on registry value
        for (let i = 0; i < this.registry.get('lives'); i++) {
            const heart = this.add.image(startX + (i * 80), heartY, 'life_heart');
            heart.setScale(0.175);
            this.hearts.add(heart);
        }

        // Store the hearts in an array if you need to reference them later
        this.livesArray = this.hearts.getChildren();
    }

    // In ThePlanetOfDoomScene.js

    createSettingsMenu() {
        // Create settings button (gear icon)
        // Create a graphics object for the gear icon
        const gear = this.add.graphics();

        // Style for the gear
        gear.lineStyle(3, 0xFFFFFF); // White color, 3px line width
        gear.fillStyle(0x666666, 1); // Gray fill with alpha 1

        // Draw the gear
        const centerX = this.game.config.width - 50;
        const centerY = 50;
        const outerRadius = 15;
        const innerRadius = 8;
        const teeth = 8;

        // Begin path for the gear
        gear.beginPath();

        // Draw the outer circle with teeth
        for (let i = 0; i < teeth; i++) {
            const angle = (Math.PI * 2 * i) / teeth;
            const nextAngle = (Math.PI * 2 * (i + 1)) / teeth;

            // Outer point
            const outerX = centerX + Math.cos(angle) * (outerRadius + 5);
            const outerY = centerY + Math.sin(angle) * (outerRadius + 5);

            // Inner points
            const innerX1 = centerX + Math.cos(angle) * outerRadius;
            const innerY1 = centerY + Math.sin(angle) * outerRadius;
            const innerX2 = centerX + Math.cos(nextAngle) * outerRadius;
            const innerY2 = centerY + Math.sin(nextAngle) * outerRadius;

            if (i === 0) {
                gear.moveTo(outerX, outerY);
            }

            gear.lineTo(outerX, outerY);
            gear.lineTo(innerX1, innerY1);
            gear.lineTo(innerX2, innerY2);
        }
        gear.closePath();
        gear.fillPath();
        gear.strokePath();

        // Draw the inner circle
        gear.beginPath();
        gear.arc(centerX, centerY, innerRadius, 0, Math.PI * 2);
        gear.fillStyle(0x444444, 1);
        gear.fillPath();
        gear.strokePath();

        // Make the gear interactive
        const hitArea = new Phaser.Geom.Circle(centerX, centerY, outerRadius + 5);
        gear.setInteractive(hitArea, Phaser.Geom.Circle.Contains);

        // Set the gear's scroll factor and depth
        gear.setScrollFactor(0);
        gear.setDepth(1000);

        // Store reference to the gear
        this.settingsButton = gear;


        // Create settings panel (initially hidden)
        this.settingsPanel = this.add.container(this.game.config.width - 200, 100);
        this.settingsPanel.setScrollFactor(0);
        this.settingsPanel.setDepth(1000);

        // Panel background
        const panelBg = this.add.rectangle(0, 0, 180, 160, 0x000000, 0.8);
        panelBg.setOrigin(0);

        // Pause button
        this.pauseButton = this.add.container(20, 20);
        const pauseBg = this.add.rectangle(0, 0, 140, 30, 0xff69b4).setOrigin(0);
        const pauseText = this.add.text(10, 5, 'Home', {
            fontSize: '16px',
            color: '#ffffff'
        });
        this.pauseButton.add([pauseBg, pauseText]);
        this.pauseButton.setInteractive(new Phaser.Geom.Rectangle(0, 0, 140, 30), Phaser.Geom.Rectangle.Contains);

        // Music toggle button
        this.musicButton = this.add.container(20, 60);
        const musicBg = this.add.rectangle(0, 0, 140, 30, 0xff69b4).setOrigin(0);
        const musicText = this.add.text(10, 5, 'Music: ON', {
            fontSize: '16px',
            color: '#ffffff'
        });
        this.musicButton.add([musicBg, musicText]);
        this.musicButton.setInteractive(new Phaser.Geom.Rectangle(0, 0, 140, 30), Phaser.Geom.Rectangle.Contains);

        // Add all elements to the panel
        this.settingsPanel.add([panelBg, this.pauseButton, this.musicButton ]);

        // Initially hide the panel
        this.settingsPanel.setVisible(false);

        // Track states
        this.isPaused = false;
        this.isMusicOn = true;
        this.isSoundOn = true;

        // Add click handlers
        this.settingsButton.on('pointerdown', () => {
            this.settingsPanel.setVisible(!this.settingsPanel.visible);
        });

        this.pauseButton.on('pointerdown', () => {
            // Clean up current scene
            this.scene.stop();
            // Navigate to Chapters screen
            window.location.href = '/chapters';
        });

        this.musicButton.on('pointerdown', () => {
            this.isMusicOn = !this.isMusicOn;
            musicText.setText(`Music: ${this.isMusicOn ? 'ON' : 'OFF'}`);
            if (this.backgroundMusic) {
                if (this.isMusicOn) {
                    this.backgroundMusic.resume();
                } else {
                    this.backgroundMusic.pause();
                }
            }
        });
    }



    handleLevelFinish() {
        // Pause the game physics
        this.physics.pause();
        this.stopEverything();

        const gameWidth = this.game.config.width;
        const gameHeight = this.game.config.height;

        // Create semi-transparent dark overlay
        const overlay = this.add.graphics();
        overlay.fillStyle(0x000000, 0.7);
        overlay.fillRect(0, 0, gameWidth, gameHeight);
        overlay.setDepth(1001);

        // Calculate vertical positions without panel reference
        const centerY = gameHeight / 2;

        const levelText = 'LEVEL ' + this.level + ' DONE!';
        this.level++;

        // Add level text
        const congratsText = this.add.text(gameWidth / 2, centerY - 50, levelText, {
            fontSize: '48px',
            fontFamily: 'Arial',
            fill: '#FF69B4',
            stroke: '#FFFFFF',
            strokeThickness: 2,
            shadow: {
                offsetX: 0,
                offsetY: 0,
                color: '#FF69B4',
                blur: 10,
                stroke: true,
                fill: true
            }
        });
        congratsText.setOrigin(0.5);
        congratsText.setDepth(1003);

        // Create next level button
        const buttonX = gameWidth / 2;
        const buttonY = centerY + 50;

        // Create rectangle button
        const buttonWidth = 100;
        const buttonHeight = 40;
        const nextButton = this.add.rectangle(buttonX, buttonY, buttonWidth, buttonHeight, 0xffffff);
        nextButton.setDepth(1003);
        nextButton.setInteractive();

        // Add text on button
        const buttonText = this.add.text(buttonX, buttonY, 'NEXT', {
            fontFamily: 'Arial',
            fontSize: '24px',
            color: '#000000',
            align: 'center'
        });
        buttonText.setOrigin(0.5);
        buttonText.setDepth(1004);

        // Button hover effects
        nextButton.on('pointerover', () => {
            nextButton.setFillStyle(0xcccccc); // Darker shade of white on hover
        });

        nextButton.on('pointerout', () => {
            nextButton.setFillStyle(0xffffff); // Back to white
        });

        // Button click handler
        nextButton.on('pointerdown', () => {

            // Resume physics
            this.physics.resume();

            // Clean up completion screen elements
            overlay.destroy();
            congratsText.destroy();
            nextButton.destroy();

            // Reset the scene for next level
            this.runNewLevelCommands();
        });

        if (this.level === 8) {
            // Create a semi-transparent dark overlay
            const overlay = this.add.rectangle(
                0,
                0,
                this.game.config.width,
                this.game.config.height,
                0x000000,
                0.7
            );
            overlay.setOrigin(0, 0);
            overlay.setScrollFactor(0);
            overlay.setDepth(2000);

            // Create the congratulations text
            const congratsText = this.add.text(
                this.game.config.width / 2,
                this.game.config.height / 2 - 50,
                'Congratulations!\nChapter Complete!!',
                {
                    fontFamily: 'Arial',
                    fontSize: '32px',
                    color: '#ffffff',
                    align: 'center',
                    fontStyle: 'bold'
                }
            );
            congratsText.setOrigin(0.5);
            congratsText.setScrollFactor(0);
            congratsText.setDepth(2001);

            // Create the "come back soon" text
            const comeBackText = this.add.text(
                this.game.config.width / 2,
                this.game.config.height / 2 + 50,
                'Come back soon when we add more levels!',
                {
                    fontFamily: 'Arial',
                    fontSize: '24px',
                    color: '#ffffff',
                    align: 'center'
                }
            );
            comeBackText.setOrigin(0.5);
            comeBackText.setScrollFactor(0);
            comeBackText.setDepth(2001);

            // Optional: Add a "Return to Chapters" button
            const returnButton = this.add.rectangle(
                this.game.config.width / 2,
                this.game.config.height / 2 + 120,
                200,
                50,
                0x4a90e2
            );
            returnButton.setScrollFactor(0);
            returnButton.setDepth(2001);
            returnButton.setInteractive({ useHandCursor: true });

            const buttonText = this.add.text(
                this.game.config.width / 2,
                this.game.config.height / 2 + 120,
                'Return to Chapters',
                {
                    fontFamily: 'Arial',
                    fontSize: '20px',
                    color: '#ffffff'
                }
            );
            buttonText.setOrigin(0.5);
            buttonText.setScrollFactor(0);
            buttonText.setDepth(2002);

            returnButton.on('pointerdown', () => {
                this.scene.stop();
                window.location.href = '/chapters';
            });

            // Optional: Add a subtle animation to the texts
            this.tweens.add({
                targets: [congratsText, comeBackText],
                y: '+=10',
                duration: 1500,
                yoyo: true,
                repeat: -1,
                ease: 'Sine.easeInOut'
            });
        } else if (this.level > -1) {
            // Get coins from localStorage or your game state
            const gameData = JSON.parse(localStorage.getItem('gameData') || '{}');
            const coins = gameData.totalCoins || 0;
            console.log("number of coins: " + coins);

            const shop = new Shop(this.level, coins, () => {
                // This callback runs when the shop is closed
                // Resume physics
                this.physics.resume();

                // Clean up completion screen elements
                overlay.destroy();
                congratsText.destroy();
                nextButton.destroy();

                // Reset the scene for next level
                this.runNewLevelCommands();
            });
            shop.show();
        }

    } // end of handleLevelFinish



    runNewLevelCommands(){
        this.levelCompleted = false;
        this.laserActive = false;
        this.isGameOver = false;
        this.player.active = true;
        // Reset the scene for next level
        this.scene.restart();
    }

    updateSpeedBar(speed) {
        // Check if components exist before updating
        if (!this.speedFill || !this.speedTextBar || !this.barDimensions) {
            return;
        }

        const maxSpeed = 100;
        const fillPercentage = Phaser.Math.Clamp(speed / maxSpeed, 0, 1);

        // Update the fill bar
        this.speedFill.clear();
        // Change color based on whether speed meets target
        this.speedFill.fillStyle(speed >= this.targetSpeed ? 0x00FF00 : 0xFFD000, 1);
        this.speedFill.fillRect(
            this.barDimensions.x,
            this.barDimensions.y,
            this.barDimensions.width * fillPercentage,
            this.barDimensions.height
        );

        // Update the text with one decimal place
        this.speedTextBar.setText(speed.toFixed(1));
    }

    createAnimation(){
        // Create the animation
        this.anims.create({
            key: 'walk',
            frames: [
                { key: 'pink1' },
                { key: 'pink2' },
                { key: 'pink3' },
                { key: 'pink4' },
                { key: 'pink5' },
                { key: 'pink6' },
            ],
            frameRate: 2, // Adjust this number to make animation faster/slower
            repeat: 0    // -1 means loop forever
        });
    }

    createEnemiesYo(){
        console.log("create enemies called");
        // Initialize enemies array if it somehow got cleared
        if (!this.enemies) {
            this.enemies = [];
        }

        // Start spawning drones
        this.time.addEvent({
            delay: this.enemySpawnTime,
            callback: this.addDrone,
            callbackScope: this,
            loop: true
        });

        /*// Start spawning drones
        this.time.addEvent({
            delay: this.enemySpawnTime,
            callback: this.addRobot,
            callbackScope: this,
            loop: true
        });*/
    }

    createPlayerAndObstaclesCollisions(){
        // In your create method or where you set up collisions
        // Add both a collider and an overlap check
        this.physics.add.collider(
            this.player,
            this.obstacles,
            this.handleObstacleCollision,
            null,
            this
        );

        this.physics.add.overlap(
            this.player,
            this.obstacles,
            this.handleObstacleCollision,
            null,
            this
        );
    }

    //Level management
    addLevelDetails(level){
        console.log("add level details called");
        this.areThereStarsOnThisLevel = false;
        this.hasThereJustBeenACollision = false;
        this.areThereBackgroundsOnThisLevel = false;
        this.isThereEnemiesOnThisLevel = false;
        this.isThereObastaclesOnThisLevel = false;
        this.areThereRobotsOnThisLevel = false;
        this.areThereTanksOnThisLevel = false;
        switch(level){
            case 0:
                console.log("level 0");
                this.startGame();
                this.showInstructionsForLevel(level);
                break;
            case 1:
                console.log("level 1");
                this.addObstacles(1);
                this.isThereObastaclesOnThisLevel = true;
                this.showInstructionsForLevel(level);
                break;
            case 2:
                console.log("level 2");
                this.addObstacles(2);
                this.isThereObastaclesOnThisLevel = true;
                this.showInstructionsForLevel(level);
                break;
            case 3:
                console.log("level 3");
                this.addRobot(3);
                this.areThereRobotsOnThisLevel = true;
                this.showInstructionsForLevel(level);
                this.createStarfield();
                this.areThereStarsOnThisLevel = true;
                break;
            case 4:
                console.log("level 4");
                this.addRobot(1);
                this.areThereRobotsOnThisLevel = true;
                this.showInstructionsForLevel(level);
                this.createStarfield();
                this.areThereStarsOnThisLevel = true;
                break;
            case 5:
                console.log("level 5");
                this.createBackgrounds(4);
                this.addRobot(10);
                this.areThereBackgroundsOnThisLevel = true;
                this.areThereRobotsOnThisLevel = true;
                this.showInstructionsForLevel(level);
                this.addObstacles(2);
                this.isThereObastaclesOnThisLevel = true;
                //this.addObstacles(4);
                //this.isThereObastaclesOnThisLevel = true;
                break;
            case 6:
                console.log("level 6");
                this.createBackgrounds(6);
                this.showInstructionsForLevel(level);
                this.addTank(18);
                this.areThereBackgroundsOnThisLevel = true;
                this.areThereTanksOnThisLevel = true;
                break;
            case 7:
                console.log("level 6");
                this.createBackgrounds(7);
                this.showInstructionsForLevel(level);
                this.addTank(12);
                //this.addRobot(10);
               // this.areThereRobotsOnThisLevel = true;
                this.areThereBackgroundsOnThisLevel = true;
                this.areThereTanksOnThisLevel = true;
                break;
            default:
                // Handle invalid or unimplemented levels
                console.warn(`Level ${level} not implemented`);
                break;
        }
        if(this.level > -1){
            this.createCoins();
        }
    }

    //function called by sdk
    create() {

        //create backgrounds based on what level it is
        //this.createBackgrounds(1);

        //music
        this.createMusic();

        //ground
        this.createGround();

        //player
        this.createPlayer();

        //keyboard
        this.createKeyboard();

        //speedometer
        //this.createSpeedometer();

        //animation
        this.createAnimation();

        //debug physics
        //this.physics.world.createDebugGraphic();

        //game not over
        this.isGameOver = false;

        //ass level details
        this.addLevelDetails(this.level);

        //add speed bar
        this.createSpeedBar();

        //add lives
        this.createLives();

        //add level display
        this.createLevelDisplay();

        //laser
        this.createLasers();

        this.createSettingsMenu();

    }

    showInstructionsForLevel(level) {
        const instructions = levelInstructions[level].gameInstructions;
        // Add 2 second delay
        this.showInstruction(instructions);
    }

    showInstruction(instruction) {
        // Create the text object
        // Create the main text
        const text = this.add.text(
            100,
            200,
            instruction,
            {
                fontFamily: 'Arial',
                fontSize: '44px',
                color: '#ffffff',
                stroke: '#000000',
                strokeThickness: 4,
                shadow: { blur: 2, color: '#000000', fill: true }
            }
        );
        text.setAlpha(0);

// Animate all text objects
        this.tweens.add({
            targets: [text],
            alpha: 1,
            duration: 1000,
            ease: 'Power2'
        });


        // Add to active instructions to be updated with scroll

        this.activeInstructions.push(text);
    }

    updateInstructions(){

    }



    // Add this method to continuously validate physics groups
    validatePhysicsGroups() {
        if (!this.laserGroup?.active || !this.enemies?.active || !this.obstacles?.active) {
            console.log('Physics groups validation failed, reinitializing...');
            this.initializePhysicsGroups();
        }
    }

    startGame() {
        this.gameStarted = true;
        // Initialize any game mechanics that should start when the panel is closed
        // For example, start enemy movements, enable player controls, etc.
    }

    createMobileControls() {
        this.mobileControls = document.createElement('div');
        this.mobileControls.id = 'mobile-joypad';

        // Create directional buttons and fire button
        const buttons = {
            up: this.createButton('⬆️', 'up'),
            down: this.createButton('⬇️', 'down'),
            left: this.createButton('⬅️', 'left'),
            right: this.createButton('➡️', 'right'),
            fire: this.createButton('🔥', 'fire')
        };

        this.mobileControls.innerHTML = `
            <div class="joypad-row">${buttons.up.outerHTML}</div>
            <div class="joypad-row">
                ${buttons.left.outerHTML}
                ${buttons.fire.outerHTML}
                ${buttons.right.outerHTML}
            </div>
            <div class="joypad-row">${buttons.down.outerHTML}</div>
        `;

        document.body.appendChild(this.mobileControls);
        this.setupMobileControls();
    }

    createButton(symbol, direction) {
        const button = document.createElement('button');
        button.className = `joypad-button ${direction}-button`;
        button.setAttribute('data-direction', direction);
        button.innerHTML = symbol;
        return button;
    }

    setupMobileControls() {
        const buttons = document.querySelectorAll('.joypad-button');

        buttons.forEach(button => {
            button.addEventListener('touchstart', (e) => {
                e.preventDefault();
                this.handleMobileInput(button.getAttribute('data-direction'), true);
                button.classList.add('pressed');
            });

            button.addEventListener('touchend', (e) => {
                e.preventDefault();
                this.handleMobileInput(button.getAttribute('data-direction'), false);
                button.classList.remove('pressed');
            });
        });
    }

    handleMobileInput(direction, isPressed) {
        switch(direction) {
            case 'up':
                this.player.moveUp = isPressed;
                break;
            case 'down':
                this.player.moveDown = isPressed;
                break;
            case 'left':
                this.player.moveLeft = isPressed;
                break;
            case 'right':
                this.player.moveRight = isPressed;
                break;
            case 'fire':
                if (isPressed) {
                    this.player.fire();
                }
                break;
        }
    }

    // Don't forget to clean up when the scene is shut down
    shutdown() {
        if (this.mobileControls) {
            this.mobileControls.remove();
        }
    }

    addObstacles(numberOfObstacles) {
        console.log(`Adding ${numberOfObstacles} obstacles`);
        this.obstacles = this.physics.add.group({
            allowGravity: false,
            immovable: true
        });

        const groundLevel = this.game.config.height - 120;
        const startX = 1800;
        const minSpacing = 1000;
        const maxSpacing = 1900;

        const obstaclePositions = [];
        let currentX = startX;

        for (let i = 0; i < numberOfObstacles; i++) {
            // Random spacing between obstacles
            const spacing = Phaser.Math.Between(minSpacing, maxSpacing);
            // Random height variation
            const heightVariation = Phaser.Math.Between(-20, 20);

            obstaclePositions.push({
                x: currentX,
                y: groundLevel - 40 + heightVariation
            });

            currentX += spacing;
        }

        obstaclePositions.forEach(pos => {
            const obstacle = this.add.sprite(pos.x, pos.y - 70, 'wall');
            obstacle.setScale(0.20);

            this.physics.add.existing(obstacle, false);

            if (obstacle.body) {
                obstacle.body.setImmovable(true);
                obstacle.body.allowGravity = false;
                obstacle.body.moves = false;
                obstacle.body.setSize(obstacle.width * 0.6, obstacle.height * 0.8);
            }

            this.obstacles.add(obstacle);
        });

        // Add collision between player and obstacles
        this.physics.add.collider(
            this.player,
            this.obstacles,
            this.handleObstacleCollision,
            (player, obstacle) => {
                // Only return true if we haven't processed a collision yet
                return !this.hasThereJustBeenACollision;
            },
            this
        );
    }

    stopEverything(){
        // Stop all movement
        this.player.body.stop();
        this.player.body.setVelocity(0, 0);
        this.player.body.setAcceleration(0, 0);
        this.player.active = false;
        // Disable player movement
        this.player.body.moves = false;
        // Stop the game scrolling
        this.scrollSpeed = 0;
        this.backgroundMusic.stop();
        // Your existing cleanup code
        //this.stars.forEach(star => star.destroy());
        //this.stars = [];

        // Destroy all colliders
        this.colliders.forEach(collider => {
            if (collider && collider.destroy) {
                collider.destroy();
            }
        });
        this.colliders = []; // Reset the colliders array

        this.clearGroups();
    }

    handleCollisionWithAnything() {
        console.log("handleCollisionWithAnything called");
        if (!this.hasThereJustBeenACollision) {
            this.hasThereJustBeenACollision = true;
            console.log("handleCollisionWithAnything REALLY called");
            // If player is invulnerable, ignore the collision
            if (this.isInvulnerable) {
                console.log("invulnerable");
                return;
            }

            // Set player as invulnerable
            this.isInvulnerable = true;

            // Optional: Add screen shake effect
            this.cameras.main.shake(10, 0.05);

            this.stopEverything();

            // Visual feedback
            this.player.setTint(0xff0000);

            // If the player has lives left, remove one
            if (this.livesArray.length > 0) {
                console.log("this.livesArray.length: " + this.livesArray.length);
                const heart = this.livesArray.pop();
                heart.destroy();
                this.registry.set('lives', this.livesArray.length);

                if (this.livesArray.length > 0) {
                    console.log("LIFE LOST, now this.livesArray.length: " + this.livesArray.length);
                    // Create game over text
                    const gameOverText = this.add.text(
                        this.cameras.main.centerX,
                        this.cameras.main.centerY - 50,
                        'LIFE LOST!!',
                        {
                            fontSize: '64px',
                            fill: '#ff0000',
                            fontStyle: 'bold'
                        }
                    ).setOrigin(0.5);

                    // Optional: Add restart button
                    const restartButton = this.add.text(
                        this.cameras.main.centerX - 140,
                        this.game.config.height / 2 + 80,
                        'Click to Restart',
                        {
                            fontSize: '32px',
                            fill: '#FFFFFF'
                        }
                    )
                        .setInteractive();

                    restartButton.on('pointerdown', () => {
                        this.backgroundMusic.stop();
                        this.isMusicPlaying = !this.isMusicPlaying;
                        this.isGameOver = false;
                        this.player.active = true;
                        this.laserActive = false;
                        this.enemies = this.physics.add.group({
                            allowGravity: false
                        });
                        this.hasThereJustBeenACollision = false;
                        this.isInvulnerable = false;
                        this.scene.restart();
                    });

                    // Optional: Add blinking effect during invulnerability
                    this.blinkEffect = this.time.addEvent({
                        delay: 200,
                        callback: () => {
                            this.player.setVisible(!this.player.visible);
                        },
                        loop: true
                    });

                    // Reset invulnerability and visibility after duration
                    /*this.time.delayedCall(this.invulnerabilityDuration, () => {
                        this.isInvulnerable = false;
                        this.player.setVisible(true);
                        if (this.blinkEffect) {
                            this.blinkEffect.remove();
                        }
                        this.player.clearTint();
                    });*/

                } else {
                    console.log("GAME OVER: this.livesArray.length: " + this.livesArray.length);
                    // Game Over
                    const gameOverText = this.add.text(
                        this.cameras.main.centerX,
                        this.cameras.main.centerY - 50,
                        'GAME OVER',
                        {
                            fontSize: '64px',
                            fill: '#ff0000',
                            fontStyle: 'bold'
                        }
                    ).setOrigin(0.5);

                    // Create pink rectangle button
                    const homeButton = this.add.rectangle(
                        this.cameras.main.centerX,
                        this.cameras.main.centerY + 60,
                        200,
                        50,
                        0xFF69B4    // Pink color (you can adjust this hex code for different shades of pink)
                    );
                    homeButton.setInteractive({ useHandCursor: true });

                    // Add black text on top of the rectangle
                    const homeText = this.add.text(
                        this.cameras.main.centerX,
                        this.cameras.main.centerY + 60,
                        'Home',
                        {
                            fontSize: '24px',
                            color: '#000000',    // Black text
                            fontStyle: 'bold'
                        }
                    ).setOrigin(0.5);

                    // Add hover effects
                    homeButton.on('pointerover', () => {
                        homeButton.setFillStyle(0xFF1493);  // Darker pink on hover
                    });

                    homeButton.on('pointerout', () => {
                        homeButton.setFillStyle(0xFF69B4);  // Back to original pink
                    });

                    // Home button functionality
                    homeButton.on('pointerdown', () => {
                        // Clear localStorage
                        localStorage.clear();
                        // Stop current scene
                        this.scene.stop();
                        window.location.href = '/chapters';
                    });

                    // Make sure all elements are visible and not affected by camera scroll
                    [gameOverText, homeButton, homeText].forEach(element => {
                        element.setScrollFactor(0);
                        element.setDepth(1021);
                    });

                    // No need for invulnerability timer in game over state
                    this.isInvulnerable = false;
                }
            }
        }
    }


    handleObstacleCollision(player, obstacle) {
       console.log("handleObstacleCollision called");
       this.handleCollisionWithAnything();
    }

    handleRobotCollision(player, obstacle) {
        console.log("handleRobotCollision called");
        this.handleCollisionWithAnything();
    }



    updateObstacles() {
        // Move obstacles with the same logic as platforms
        if (this.scrollSpeed > this.baseScrollSpeed && !this.isGameOver && this.obstacles) {
            this.obstacles.getChildren().forEach((obstacle) => {
                obstacle.x -= this.scrollSpeed;

                // Reset obstacle position when it goes off screen
                /*if (obstacle.x <= -obstacle.width) {
                    // Find rightmost obstacle
                    let rightmostX = -Infinity;
                    this.obstacles.getChildren().forEach(obstacleCheck => {
                        if (obstacleCheck.x > rightmostX) {
                            rightmostX = obstacleCheck.x;
                        }
                    });

                    // Position after rightmost obstacle with some spacing
                    obstacle.x = rightmostX + Phaser.Math.Between(800, 1200);
                }*/
            });
        }
    }


    addPlatforms() {
        // Create physics group with immovable and no gravity settings
        this.platforms = this.physics.add.group({
            allowGravity: false,
            immovable: true
        });

        const startX = this.backgrounds[0].width;

        const platformPositions = [
            { x: startX + 200, y: 300 },
            { x: startX + 600, y: 400 },
            { x: startX + 1000, y: 250 },
            { x: startX + 1400, y: 350 },
            { x: startX + 1800, y: 200 }
        ];

        platformPositions.forEach(pos => {
            const platform = this.add.rectangle(pos.x, pos.y, 200, 30, 0xFF0000);
            platform.setStrokeStyle(4, 0xFFFFFF);
            platform.setDepth(10);

            // Enable physics
            this.physics.add.existing(platform, false);

            // Ensure the platform body is properly configured
            if (platform.body) {
                platform.body.immovable = true;
                platform.body.allowGravity = false;
                platform.body.pushable = false; // Prevent being pushed by collisions
                platform.body.setSize(200, 30);
            }

            this.platforms.add(platform);
        });

        // Increase physics iterations to better handle fast movement
        this.physics.world.setFPS(120); // Increase physics updates per second

        // Add collision between player and platforms
        this.physics.add.collider(
            this.player,
            this.platforms,
            (player, platform) => {
                if (player.body.touching.down) {
                    this.isJumping = false;
                    this.canJump = true;
                }
            },
            null,
            this
        );
    }

    updatePlatforms(){
        // Apply the same scrolling logic to platforms as backgrounds
        if (this.scrollSpeed > this.baseScrollSpeed) {
            this.platforms.getChildren().forEach((platform) => {
                platform.x -= this.scrollSpeed;

                // Reset platform position when it goes off screen
                if (platform.x <= -platform.width) {
                    // Find rightmost platform
                    let rightmostX = -Infinity;
                    let rightmostPlatform = null;

                    this.platforms.getChildren().forEach(platformCheck => {
                        if (platformCheck.x > rightmostX) {
                            rightmostX = platformCheck.x;
                            rightmostPlatform = platformCheck;
                        }
                    });

                    // Position after rightmost platform
                    platform.x = rightmostX + this.game.config.width/2;

                    // Randomize y position for variety
                    platform.y = Phaser.Math.Between(200, 450);
                }
            });
        }
        // Additional collision checks for high-speed movement
        this.platforms.getChildren().forEach(platform => {
            const playerBottom = this.player.y + (this.player.height * 0.3);
            const playerTop = this.player.y - (this.player.height * 0.3);
            const platformTop = platform.y - (platform.height * 0.4);
            const platformBottom = platform.y + (platform.height * 0.4);

            // Get player sides
            const playerRight = this.player.x + (this.player.width * 0.3);
            const playerLeft = this.player.x - (this.player.width * 0.3);
            const platformRight = platform.x + (platform.width * 0.4);
            const platformLeft = platform.x - (platform.width * 0.4);

            // Check if player is within vertical bounds of platform
            const withinVerticalBounds =
                playerBottom > platformTop + (platform.height * 0.1) && // Add small offset to prevent top collision triggering
                playerTop < platformBottom - (platform.height * 0.1);   // Add small offset to prevent bottom collision triggering

            // Side collision checks
            if (withinVerticalBounds) {
                // Right side collision
                if (playerLeft <= platformRight &&
                    playerLeft >= platformRight - 10 &&
                    this.player.body.velocity.x <= 0) {
                    // Bounce right
                    this.player.x = platformRight + (this.player.width * 0.3);
                    this.player.body.velocity.x = 300; // Bounce velocity
                    return; // Exit early to prevent other collision checks
                }

                // Left side collision
                if (playerRight >= platformLeft &&
                    playerRight <= platformLeft + 10 &&
                    this.player.body.velocity.x >= 0) {
                    // Bounce left
                    this.player.x = platformLeft - (this.player.width * 0.3);
                    this.player.body.velocity.x = -300; // Bounce velocity
                    return; // Exit early to prevent other collision checks
                }
            }

            // Original top/bottom collision checks
            if (this.player.x + (this.player.width * 0.3) > platform.x - (platform.width * 0.4) &&
                this.player.x - (this.player.width * 0.3) < platform.x + (platform.width * 0.4)) {

                // Coming down fast - collision from above
                if (this.player.body.velocity.y > 0 &&
                    playerBottom >= platformTop &&
                    playerTop <= platformTop) {
                    this.player.y = platformTop - (this.player.height * 0.3);
                    this.player.body.velocity.y = 0;
                    this.isJumping = false;
                    this.canJump = true;
                }

                // Going up fast - collision from below
                if (this.player.body.velocity.y < 0 &&
                    playerTop <= platformBottom &&
                    playerBottom >= platformBottom) {
                    this.player.y = platformBottom + (this.player.height * 0.3);
                    this.player.body.velocity.y = 0;
                }
            }
        });
    }

    addOneRobot () {
        const GROUND_LEVEL = 500;
        const x = this.gameWidth + 1400;
        const y = GROUND_LEVEL;

        const robot = this.physics.add.sprite(x, y, 'drone_002');
        robot.type = 'robot';
        robot.setScale(0.4);

        // Base speed for the robot
        robot.baseSpeed = -300; // Base speed moving left

        robot.body.setAllowGravity(false);
        robot.setVisible(true);

        // Initial velocity
        robot.setVelocityX(robot.baseSpeed);

        // Store the robot reference if you need to update multiple robots
        if (!this.robots) {
            this.robots = [];
        }
        this.robots.push(robot);

        // Add collision with player
        this.physics.add.overlap(this.player, robot, this.handleObstacleCollision, null, this);
    }

    addRobot(numberOfRobots) {
        const GROUND_LEVEL = 500;
        const MIN_SPACING = 800;
        const MAX_SPACING = 1500;
        const MIN_HEIGHT = GROUND_LEVEL - 100;
        const MAX_HEIGHT = GROUND_LEVEL + 50;
        let currentX = this.gameWidth + 1400;

        if (!this.robots) {
            this.robots = [];
        }

        // Define robot types and their properties
        const robotTypes = [
            {
                type: 'drone',
                name: 'drone_002',
                speed: -300,
                extraHeight: 0,
                scale: 0.4
            },
            {
                type: 'yellow_missile',
                name: 'yellow_missile',
                speed: -600,
                extraHeight: 0,
                scale: 0.4
            }
        ];

        for (let i = 0; i < numberOfRobots; i++) {
            // Randomly select a robot type
            const robotType = Phaser.Math.RND.pick(robotTypes);

            const randomSpacing = Phaser.Math.Between(MIN_SPACING, MAX_SPACING);
            const randomY = Phaser.Math.Between(MIN_HEIGHT, MAX_HEIGHT) + robotType.extraHeight;

            const robot = this.add.sprite(currentX, randomY, robotType.name);
            robot.type = 'robot';
            robot.setScale(robotType.scale);
            robot.setDepth(1007);

            // Add physics to the sprite
            this.physics.add.existing(robot, false);

            if (robot.body) {
                robot.body.setAllowGravity(false);
                robot.baseSpeed = robotType.speed;
                robot.body.setVelocityX(robot.baseSpeed);
            }

            robot.setVisible(true);
            this.physics.add.overlap(this.player, robot, this.handleRobotCollision, null, this);
            this.robots.push(robot);
            currentX += randomSpacing;

            this.physics.add.collider(
                this.player,
                robot,
                this.handleTankCollision,
                (player, obstacle) => {
                    // Only return true if we haven't processed a collision yet
                    return !this.hasThereJustBeenACollision;
                },
                this
            );
        }
    }

    addTank(numberOfTanks) {
        const GROUND_LEVEL = 480;
        const MIN_SPACING = 800;
        const MAX_SPACING = 1500;
        let currentX = this.gameWidth + 1400;

        if (!this.tanks) {
            this.tanks = [];
        }

        // Define tank types and their properties
        const tankTypes = [
            {
                type: 'tank1',
                name: 'robot_001',
                speed: -200,  // Tanks move slower than flying robots
                scale: 0.15
            },
            {
                type: 'tank2',
                name: 'tank_2',
                speed: -450,
                scale: 0.3
            }
        ];

        for (let i = 0; i < numberOfTanks; i++) {
            // Randomly select a tank type
            const tankType = Phaser.Math.RND.pick(tankTypes);

            const randomSpacing = Phaser.Math.Between(MIN_SPACING, MAX_SPACING);
            // No random Y position - always at ground level
            const y = GROUND_LEVEL;

            const tank = this.add.sprite(currentX, y, tankType.name);
            tank.type = 'tank';
            tank.setScale(tankType.scale);
            tank.setDepth(1007);

            // Add physics to the sprite
            this.physics.add.existing(tank, false);

            if (tank.body) {
                tank.body.setAllowGravity(false);
                tank.baseSpeed = tankType.speed;
                tank.body.setVelocityX(tank.baseSpeed);

                // Optional: Adjust the collision body size if needed
                tank.body.setSize(tank.width * 0.8, tank.height * 0.8);
            }

            tank.setVisible(true);
            this.physics.add.overlap(this.player, tank, this.handleTankCollision, null, this);
            this.tanks.push(tank);
            currentX += randomSpacing;

            // Add collision between player and tank
            this.physics.add.collider(
                this.player,
                tank,
                this.handleTankCollision,
                (player, obstacle) => {
                    // Only return true if we haven't processed a collision yet
                    return !this.hasThereJustBeenACollision;
                },
                this
            );
        }
    }

    handleTankCollision(player, tank) {
        console.log("handleTankCollision called");
        if (!this.hasThereJustBeenACollision) {
            //this.hasThereJustBeenACollision = true;
            // Your collision handling code here
            this.handleCollisionWithAnything();
        }else{
            console.log("Tank Collision already processed");
        }
    }


    updateTanks() {
        try {
            if (this.tanks && !this.hasThereJustBeenACollision) {
                this.tanks.forEach(tank => {
                    if (tank.active && tank.body) {
                        const speedMultiplier = 0.5;
                        const newVelocity = tank.baseSpeed + (this.scrollSpeed * speedMultiplier);
                        tank.body.setVelocityX(newVelocity);
                    }
                });
            }
        }
        catch(error) {
            console.error('Error in updateTanks:', error);
        }
    }


    updateRobots(){
        // Update robot velocities based on scrollSpeed
        try{
            if (this.robots && !this.hasThereJustBeenACollision) {
                this.robots.forEach(robot => {
                    if (robot.active && robot.body) {  // Check for both active and body
                        // Update horizontal velocity based on scroll speed
                        const speedMultiplier = 0.5;
                        const newVelocity = robot.baseSpeed + (this.scrollSpeed * speedMultiplier);
                        robot.body.setVelocityX(newVelocity);  // Use body.setVelocityX
                    }
                });
            }
        }
        catch(error) {
            console.error('Error in updateRobots:', error);
        }
    }


    addDrone() {
        console.log("addDrone called");
        const GROUND_LEVEL = 520;
        const x = 800;
        let y = Phaser.Math.Between(100, GROUND_LEVEL - 100);
        console.log("x: " + x + " y: " + y);

        let enemy = this.physics.add.sprite(x, y, 'new_drone');
        enemy.type = 'new_drone';
        enemy.setScale(0.2);

        // Completely disable physics effects
        enemy.body.setAllowGravity(false);
        enemy.body.setImmovable(true);  // Add this to prevent physics interactions
        enemy.setVisible(true);

        // Set both X and Y velocities explicitly
        enemy.body.setVelocity(-150, 0);

        // Lock the Y axis to prevent any vertical movement
        enemy.body.setAllowGravity(false);
        enemy.body.moves = true;

        // Add to physics group
        this.enemies.add(enemy);

        console.log('Initial position:', enemy.x, enemy.y);
        console.log('Initial velocity:', enemy.body.velocity.x, enemy.body.velocity.y);

        const collider = this.physics.add.collider(
            this.player,
            enemy,
            this.handleDroneCollision,
            null,
            this
        );
        this.colliders.push(collider);
    }

    updateEnemies() {
        /*if ((!this.enemies || !this.enemies.children || !this.enemies.children.entries)){
            console.warn('Reinitializing enemies group');
            this.enemies = this.physics.add.group();
            return;
        }*/

        if(this.enemies && !this.isGameOver) {
            try{
                const enemies = this.enemies.getChildren();
                enemies.forEach(enemy => {
                    if (enemy && enemy.active) {
                        // Ensure velocity stays constant
                        enemy.body.setVelocity(-150, 0);

                        // Debug logging
                        //console.log('Enemy position:', enemy.x, enemy.y);
                        //console.log('Enemy velocity:', enemy.body.velocity.x, enemy.body.velocity.y);

                        if (enemy.x < -enemy.width) {
                            enemy.destroy();
                        }
                    }
                });
            }catch(error) {
                console.error('Error in updateEnemies:', error);
            }
        }
    }


    spawnEnemy() {
        const GROUND_LEVEL = 520;
        const enemyType = Phaser.Math.Between(0, 4);
        const x = this.gameWidth + 400;
        let y = Phaser.Math.Between(100, GROUND_LEVEL - 100);

        let enemy;
        switch (enemyType) {
            case 1: // ship
                enemy = this.physics.add.sprite(x, y, 'new_drone');
                enemy.type = 'ship';
                enemy.setScale(0.4);
                enemy.speed = 4; // Ships might be faster
                break;
            case 2: // ship2
                enemy = this.physics.add.sprite(x, y, 'new_drone'); // Make sure 'ship2' is loaded in your preload function
                enemy.type = 'ship2';
                enemy.setScale(0.4); // Adjust scale as needed
                enemy.speed = 5; // Make ship2 even faster
                break;
            case 3: // robot
                enemy = this.physics.add.sprite(x, y, 'new_drone'); // Make sure 'ship2' is loaded in your preload function
                enemy.type = 'drone';
                enemy.setScale(0.4); // Adjust scale as needed
                enemy.speed = 2; // Make robot slow
                y = GROUND_LEVEL;
                break;
            case 4: // robot
                enemy = this.physics.add.sprite(x, y, 'new_drone'); // Make sure 'ship2' is loaded in your preload function
                enemy.type = 'drone_002';
                enemy.setScale(0.4); // Adjust scale as needed
                enemy.speed = 3; // Make ship2 even faster
                y = GROUND_LEVEL;
                break;
            default: // drone (case 0)
                enemy = this.physics.add.sprite(x, y, 'new_drone');
                enemy.type = 'drone';
                enemy.setScale(0.4);
                enemy.speed = 2; // Drones might be slower
                break;
        }

        enemy.body.setAllowGravity(false);
        enemy.setVisible(true);
        enemy.setVelocityX(-150);

        // Just add to the physics group
        this.enemies.add(enemy);

        // Add collision with player
        this.physics.add.overlap(this.player, enemy, this.handleDroneCollision, null, this);
    }

    // Add this method to randomly choose between drone and ship
    spawnEnemyYo() {
        const GROUND_LEVEL = 520;
        // Randomly decide between three enemy types (0: drone, 1: ship, 2: ship2, 3:  robot)
        const enemyType = Phaser.Math.Between(0, 4);

        const x = this.gameWidth + 400;
        let y = Phaser.Math.Between(100, GROUND_LEVEL - 100);

        let enemy;
        switch (enemyType) {
            case 1: // ship
                enemy = this.physics.add.sprite(x, y, 'new_drone');
                enemy.type = 'ship';
                enemy.setScale(0.4);
                enemy.speed = 4; // Ships might be faster
                break;
            case 2: // ship2
                enemy = this.physics.add.sprite(x, y, 'new_drone'); // Make sure 'ship2' is loaded in your preload function
                enemy.type = 'ship2';
                enemy.setScale(0.4); // Adjust scale as needed
                enemy.speed = 5; // Make ship2 even faster
                break;
            case 3: // robot
                enemy = this.physics.add.sprite(x, y, 'new_drone'); // Make sure 'ship2' is loaded in your preload function
                enemy.type = 'drone';
                enemy.setScale(0.4); // Adjust scale as needed
                enemy.speed = 2; // Make robot slow
                y = GROUND_LEVEL;
                break;
            case 4: // robot
                enemy = this.physics.add.sprite(x, y, 'new_drone'); // Make sure 'ship2' is loaded in your preload function
                enemy.type = 'drone_002';
                enemy.setScale(0.4); // Adjust scale as needed
                enemy.speed = 3; // Make ship2 even faster
                y = GROUND_LEVEL;
                break;
            default: // drone (case 0)
                enemy = this.physics.add.sprite(x, y, 'new_drone');
                enemy.type = 'drone';
                enemy.setScale(0.4);
                enemy.speed = 2; // Drones might be slower
                break;
        }

        enemy.body.setAllowGravity(false);
        enemy.setVisible(true);
        enemy.setVelocityX(-150);  // Move left

        // Add collision with player
        this.physics.add.overlap(this.player, enemy, this.handleDroneCollision, null, this);

        // Make sure we're adding to the array
        if (!this.enemies) {
            this.enemies = [];
        }
        this.enemies.push(enemy);
    }


    handleDroneCollision(player, drone) {
        console.log("handelDroneCollision called");
        // Create explosion effect (you can enhance this)
        const explosion = this.add.circle(
            drone.x,
            drone.y,
            20,
            0xff0000
        );

        // Fade out explosion
        this.tweens.add({
            targets: explosion,
            alpha: 0,
            duration: 500,
            ease: 'Power2',
            onComplete: () => {
                explosion.destroy();
            }
        });

        // Remove the drone
        drone.destroy();

        this.handleCollisionWithAnything();
    }

    // In your scene's create function, add this:
    // In your scene's create function
    createBlurEffect() {
        this.blurEmitter = this.add.particles(0, 0, 'dot', {
            lifespan: { min: 100, max: 200 },
            scale: { start: 0.6, end: 0.1 },
            alpha: { start: 0.6, end: 0 },
            tint: 0xFF69B4,
            blendMode: 'ADD',
            frequency: 10,
            follow: this.player,
            followOffset: { x: -20, y: 0 },
            speed: { min: 0, max: 20 },
            angle: { min: 0, max: 360 },
            quantity: 200
        });

        // Start stopped
        this.blurEmitter.start();
        this.blurEmitter.setDepth(this.player.depth + 1);

        //this.blurEmitter.setPosition(300, 300);

    }

    updatePlayer(){
        if (this.player && this.player.active) {
            // Make the glow follow the player
            this.glow.clear();
            const radius = 90; // Same as above

            // Redraw the circles at the new position
            this.glow.fillStyle(0xffb6c1, 0.14);
            this.glow.fillCircle(this.player.x, this.player.y, radius);

            this.glow.fillStyle(0xffb6c1, 0.34);
            this.glow.fillCircle(this.player.x, this.player.y, radius * 0.7);

            this.glow.fillStyle(0xffb6c1, 0.45);
            this.glow.fillCircle(this.player.x, this.player.y, radius * 0.5);

            this.frameCounter++;

            if (this.frameCounter >= this.animationSpeed) {
                this.frameCounter = 0;
                // Toggle between 0 and 1 to represent images 2 and 3
                this.animationFrame = this.animationFrame === 0 ? 1 : 0;

                // Update the sprite texture based on animation frame
                if (this.animationFrame === 0) {
                    this.player.setTexture('pink_final_run');
                } else {
                    this.player.setTexture('pink_final_run');
                }
            }

            if (this.cursors.left.isDown || this.player.moveLeft) {
                this.player.setFlipX(true);
                // Gradually decelerate when moving left
                if (this.scrollSpeed > this.baseScrollSpeed) {
                    this.scrollSpeed = Math.max(this.baseScrollSpeed, this.scrollSpeed - this.deceleration);
                }
                this.player.setVelocityX(-this.playerSpeed);
                //this.player.play('walk', true);
                this.player.flipX = true;
            } else if (this.cursors.right.isDown || this.player.moveRight) {
                this.player.setFlipX(false);
                if (this.player.x < 190) {
                    // Normal movement until middle
                    this.player.setVelocityX(this.playerSpeed);
                    // this.player.play('walk', true);
                    this.player.flipX = false;

                    // Increase scroll speed while right is held
                    this.scrollSpeed += this.acceleration;
                    this.scrollSpeed = Math.min(this.scrollSpeed, this.maxScrollSpeed);

                } else {
                    // Lock at middle position
                    this.player.x = 190;
                    this.player.setVelocityX(0);
                    //this.player.stop();
                    // this.player.play('walk', true);

                    // Increase scroll speed while right is held
                    this.scrollSpeed += this.acceleration;
                    this.scrollSpeed = Math.min(this.scrollSpeed, this.maxScrollSpeed);
                }



            } else {
                // When no keys are pressed, gradually decelerate
                if (this.scrollSpeed > this.baseScrollSpeed) {
                    this.scrollSpeed = Math.max(this.baseScrollSpeed, this.scrollSpeed - this.deceleration);
                }
                this.player.setVelocityX(0);
                this.player.setTexture('pink_final_001');
                this.animationFrame = 0;
                this.frameCounter = 0;
            }

            // Update the speed display - round to 1 decimal place
            //const displaySpeed = Math.round(this.scrollSpeed * 10) / 10;
            //this.speedText.setText(`Speed: ${displaySpeed}`);

            //this.updateSpeedometer();

            // Modify your jump logic
            if (this.cursors.up.isDown && this.canJump && !this.isJumping) {
                this.player.setVelocityY(-600);
                this.isJumping = true;
                this.canJump = false;
            }

            // Remove the down key controls since we want gravity to handle falling
            // Keep the horizontal movement during jump
            /*if (this.cursors.right.isDown) {
                this.player.setVelocityX(400);
            } else if (this.cursors.left.isDown) {
                this.player.setVelocityX(-400);
            } else {
                this.player.setVelocityX(0);  // Stop horizontal movement when no direction is pressed
            }*/

            // Keep the ground detection
            if (this.player.body.touching.down || this.player.body.blocked.down) {
                this.isJumping = false;
                this.canJump = true;
            }

            /*if (this.cursors.down.isDown && this.canJump && !this.isJumping) {
                console.log("down button");
                this.player.setVelocityY(1600);
                this.isJumping = true;
                this.canJump = false;
            }*/

            const currentSpeed = this.scrollSpeed;
            if (currentSpeed >= this.targetSpeed && !this.levelCompleted) {
                this.levelCompleted = true; // Flag to prevent multiple triggers
                this.handleLevelFinish();
            }

            // Get current speed from speedBar (assuming you have a way to track this)

            // Check if speed is above or below flying threshold
            if (currentSpeed >= 30) {
                if (!this.isFlying) {
                    this.player.setGravityY(0); // Turn off gravity
                    this.isFlying = true;
                }
            } else {
                if (this.isFlying) {
                    this.player.setGravityY(400); // Reset to normal gravity (adjust value as needed)
                    this.isFlying = false;
                }
            }

            // Modify up/down controls for flying mode
            if (this.isFlying) {
                // Flying controls - smooth up/down movement
                if (this.cursors.up.isDown) {
                    this.player.setVelocityY(-300); // Gentler upward movement
                } else if (this.cursors.down.isDown) {
                    this.player.setVelocityY(300); // Gentler downward movement
                } else {
                    this.player.setVelocityY(0); // Hover in place when no keys pressed
                }
            } else {
                // Normal jumping/falling controls
                if (this.cursors.up.isDown && this.canJump && !this.isJumping) {
                    this.player.setVelocityY(-600);
                    this.isJumping = true;
                    this.canJump = false;
                }
                if (this.cursors.down.isDown && this.canJump && !this.isJumping) {
                    this.player.setVelocityY(1600);
                    this.isJumping = true;
                    this.canJump = false;
                }
            }


        } //end of if (this.player && this.player.active)
        else {
            // Player is not moving, reset to default stance
            this.player.setTexture('pink_final_001');
            this.animationFrame = 0;
            this.frameCounter = 0;
        }
    }


    update(time, delta) {
        // Handle player movement
        this.updatePlayer();

        if (this.isThereEnemiesOnThisLevel) {
            this.updateEnemies();
        }

        // this.updatePlatforms();
        if (this.isThereObastaclesOnThisLevel) {
            this.updateObstacles();
        }

       this.updateSpeedBar(this.scrollSpeed);

        if (this.areThereStarsOnThisLevel) {
            this.updateStars();
        }

       this.updateStars();

        this.updateLasers();

        // Update instruction text positions based on scroll speed
        this.activeInstructions.forEach((text, index) => {
            if (text && text.active) {
                text.x -= this.scrollSpeed * (700 / 1000); // Multiply by delta/1000 for smooth movement

                // Check if text has gone off screen and hasn't been marked for delayed removal
                if (text.x < -text.width && !text.markedForRemoval) {
                    text.markedForRemoval = true; // Mark it so we don't trigger this again

                    // Set timeout for 10 seconds
                    setTimeout(() => {
                        text.destroy();
                        this.activeInstructions.splice(index, 1);
                        console.log("instructions moved off screen");
                        if (this.level === 0) {
                            this.handleLevelFinish();
                        }
                    }, 4000); // 10000 milliseconds = 10 seconds
                }
            }
        });

        if(this.areThereRobotsOnThisLevel){
            this.updateRobots();
        }
        if(this.areThereTanksOnThisLevel){
            this.updateTanks();
        }


        if (this.level > 3){
            this.updateBackgrounds();
        }

        if (this.level > 2){
            this.updateCoins();
        }


       // console.log(`Player at x: ${this.player.x}, y: ${this.player.y}`);
    }
}
