// src/components/Game.js
import { useEffect, useRef } from 'react';
import Phaser from 'phaser';
//import { GameScene } from '../game/scenes/GameScene';
import ThePlanetOfDoomScene from '../game/scenes/ThePlanetOfDoomScene';

export const Game = () => {
    const gameRef = useRef(null);

    useEffect(() => {
        if (!gameRef.current) {
            const config = {
                type: Phaser.AUTO,
                parent: 'game-container',
                width: window.innerWidth * 0.98, // 98% of window width
                height: window.innerHeight * 0.98,
                physics: {
                    default: 'arcade',
                    arcade: {
                        gravity: { y: 300 },
                        debug: false
                    }
                },
                scene: [ThePlanetOfDoomScene]
            };

            gameRef.current = new Phaser.Game(config);
            // Start the preferred scene
            // Start initial scene, make sure others are stopped
            //gameRef.current.scene.stop('GameScene');
            gameRef.current.scene.stop('ThePlanetOfDoomScene');
            gameRef.current.scene.start('ThePlanetOfDoomScene');


            // Optional: Handle window resizing
            const handleResize = () => {
                if (gameRef.current) {
                    gameRef.current.scale.resize(window.innerWidth * 0.98, 600);
                }
            };

            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
                if (gameRef.current) {
                    gameRef.current.destroy(true);
                    gameRef.current = null;
                }
            };
        }
    }, []);


    return (
        <div>
            <div id="game-container" style={{ width: '98%', margin: '0 auto' }} />
        </div>
    );
};
