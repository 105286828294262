import React, { useState, useEffect } from 'react';
import bg_image from  '../../assets/images/welcome/pink_blur_welcome.jpg';
import './Home.css';
import './Hyperspace.css';
import Chapters from "../../components/Chapters";
import {Button} from "react-bootstrap";

export const Home = () => {
    const [showGame, setShowGame] = useState(false);
    const [inputCode, setInputCode] = useState('');
    const [error, setError] = useState('');
    const [showWelcome, setShowWelcome] = useState(true);
    const [showHyperspace, setShowHyperspace] = useState(false);
    const [loginSuccessful, setLoginSuccessful] = useState(false);


// Call this when showing hyperspace
    // Modify the useEffect to clean up the stars when component unmounts
    useEffect(() => {
        if (showHyperspace) {
            createStars();

            // Optional: Clean up function to remove stars when effect ends
            return () => {
                const container = document.querySelector('.hyperspace-container');
                if (container) {
                    container.innerHTML = '';
                }
            };
        }
    }, [showHyperspace]);

    const backgroundStyle = {
        backgroundImage: bg_image ? `url(${bg_image})` : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'top center', // Changed to 'top center' to prevent top cropping
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
        width: '100%',
        position: 'absolute', // Add this
        top: 0,              // Add this
        left: 0,             // Add this
        right: 0,            // Add this
        bottom: 0,           // Add this
    };

    const createStars = () => {
        const container = document.querySelector('.hyperspace-container');
        const starCount = 400;

        for (let i = 0; i < starCount; i++) {
            const star = document.createElement('div');
            star.className = 'star';

            // Random position from center
            const angle = Math.random() * Math.PI * 2;
            const distance = Math.random() * 250 + 100;

            star.style.left = `${50 + Math.cos(angle) * distance/10}%`;
            star.style.top = `${50 + Math.sin(angle) * distance/10}%`;

            // Random delay for each star
            star.style.animationDelay = `${Math.random() * 2}s`;

            container.appendChild(star);
        }
    };

    const handleLogin = () => {
        // Navigate to chapters page directly
        window.location.href = '/chapters';
    };



    const correctCode = '99779977';

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (inputCode === correctCode) {
            //setShowGame(true);
            setShowWelcome(false);
            //setShowHyperspace(true);
            setLoginSuccessful(true);
            await handleLogin();
        } else {
            setError('Incorrect code. Please try again.');
            setInputCode('');
        }
    };

    const handleStartGame = () => {
        console.log("handlestartgame called");
        setShowWelcome(false);
        //setShowGame(true);
        setShowHyperspace(true);
    };


    // Add the hyperspace effect component to your render
    return (
        <>
        {showHyperspace && (
            <div className="hyperspace-container active">
                <div className="hyperspace-stars">
                    {/* Generate multiple stars programmatically */}
                    {Array(200).fill().map((_, i) => (
                        <div
                            key={i}
                            className="star"
                            style={{
                                position: 'absolute',
                                left: `${Math.random() * 100}%`,
                                top: `${Math.random() * 100}%`,
                                width: '2px',
                                height: '2px',
                                background: '#ffc0cb',
                                borderRadius: '50%',
                            }}
                        />
                    ))}
                </div>
            </div>
        )}


        {/* (showGame) && (
            <div className="flex flex-col items-center justify-center min-h-screen" >
                <div className="text-center">
                    <h1 className="text-6xl text-pink-500 mb-4">The Pink Blur</h1>
                    <p className="text-xl text-white mb-8">The faster you go the faster you can go faster</p>
                    <button
                        onClick={handleStartGame}
                        className="px-8 py-3 bg-pink-600 text-white rounded-lg hover:bg-pink-700 transition-colors"
                    >
                        Press to Start
                    </button>
                </div>
            </div>
            )
        */}

            {showGame && <Chapters />}


    { showWelcome && (
        <div className="flex flex-col items-center justify-end min-h-screen" style={backgroundStyle}>
            <form
                onSubmit={handleSubmit}
                className="loginField"
            >

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleLogin}
                    style={{
                        marginTop: '20px',
                        width: '200px',
                        height: '50px',
                        fontSize: '1.2em',
                        backgroundColor: 'pink',
                        color: 'black',
                        border: 'solid 2px black',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        fontWeight: 'bold'
                    }}
                >
                    Enter
                </Button>
            </form>
        </div>
    )}
        </>);
};
