/**
 * Created by andyosullivan on 15/06/2024
 */

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Home } from './containers/Home/Home';
import Chapters from "./components/Chapters";
import {Game} from "./components/Game";
import {GameChapterTwo} from "./components/GameChapterTwo";

function NewRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/chapters" element={<Chapters />} />
            <Route path="/game" element={<Game />} />
            <Route path="/game-chapter-two" element={<GameChapterTwo />} />
        </Routes>
    );
}

export default NewRoutes;





