import './Shop.css';
import laser_pulse from '../assets/images/player/laser_pulse.png';
import double_laser_pulse from '../assets/images/player/double_laser_beam.png';
import life_heart from '../assets/images/powerUps/life_heart.png';
import ship_shop from '../assets/images/player/ship_shop.png';


export default  class Shop {
    constructor(level, coins, onPurchaseComplete) {
        this.coins = coins;
        this.onPurchaseComplete = onPurchaseComplete;
        this.level = level;
        this.items = [
            {
                id: 'laser_pulse',
                name: 'Laser Blast',
                price: 5,
                description: 'Fire lasers from the Pink Blur with the SPACE BAR!',
                image: laser_pulse,
                purchased: false
            },
            {
                id: 'double_laser_beam',
                name: 'Double Laser Beam',
                price: 40,
                description: 'What\'s better than one laser beam? Two!',
                image: double_laser_pulse,
                purchased: false
            },
            {
                id: 'extra_life',
                name: 'Extra Life',
                price: 500,
                description: 'One more precious life!',
                image: life_heart,
                purchased: false
            },
            {
                id: 'ship',
                name: 'Space Ship',
                price: 1000,
                description: 'Go even faster in the Pink Wonder!',
                image: ship_shop,
                purchased: false
            }
        ];
    }

    createShopElement() {
        const shopOverlay = document.createElement('div');
        shopOverlay.className = 'shop-overlay';

        const shopContent = document.createElement('div');
        shopContent.className = 'shop-content';

        const header = document.createElement('div');
        header.className = 'shop-header';
        header.innerHTML = `
            <h1>Level ${this.level} complete! Welcome to the Space Shop</h1>
            <div class="coin-display">🪙 ${this.coins} coins</div>
        `;

        const itemsContainer = document.createElement('div');
        itemsContainer.className = 'items-container';

        this.items.forEach(item => {
            const itemElement = this.createItemElement(item);
            itemsContainer.appendChild(itemElement);
        });

        const closeButton = document.createElement('button');
        closeButton.className = 'close-button';
        closeButton.textContent = 'Continue to Next Level';
        closeButton.onclick = () => {
            shopOverlay.remove();
            this.onPurchaseComplete();
        };

        shopContent.appendChild(header);
        shopContent.appendChild(itemsContainer);
        shopContent.appendChild(closeButton);
        shopOverlay.appendChild(shopContent);

        return shopOverlay;
    }

    createItemElement(item) {
        const itemElement = document.createElement('div');
        itemElement.className = 'shop-item';

        itemElement.innerHTML = `
        <img src="${item.image}" alt="${item.name}">
        <h3>${item.name}</h3>
        <p>${item.description}</p>
        <div class="price">🪙 ${item.price}</div>
    `;

        const buyButton = document.createElement('button');
        buyButton.textContent = 'Buy';
        buyButton.className = 'buy-button';

        // Check localStorage for previously purchased items
        const gameData = JSON.parse(localStorage.getItem('gameData') || '{}');
        const purchases = gameData.purchases || {};
        const isPurchased = purchases[item.id] === true;

        // Update the item's purchased status based on localStorage
        item.purchased = isPurchased;

        if (this.coins < item.price || isPurchased) {
            buyButton.disabled = true;
            buyButton.textContent = isPurchased ? 'Purchased' : 'Not enough coins';
        }

        buyButton.onclick = () => this.purchaseItem(item, itemElement);
        itemElement.appendChild(buyButton);

        return itemElement;
    }


    purchaseItem(item, itemElement) {
        if (this.coins >= item.price && !item.purchased) {
            this.coins -= item.price;
            item.purchased = true;

            // Update local storage with purchases
            const gameData = JSON.parse(localStorage.getItem('gameData') || '{}');
            if (!gameData.purchases) {
                gameData.purchases = {};
            }
            gameData.purchases[item.id] = true;  // Store as key-value pair
            gameData.coins = this.coins;
            localStorage.setItem('gameData', JSON.stringify(gameData));

            // Update UI
            document.querySelector('.coin-display').textContent = `🪙 ${this.coins} coins`;
            const buyButton = itemElement.querySelector('.buy-button');
            buyButton.disabled = true;
            buyButton.textContent = 'Purchased';
        }
    }


    show() {
        document.body.appendChild(this.createShopElement());
    }
}
