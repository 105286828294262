import React from 'react';
import { useNavigate } from 'react-router-dom';
import planet_doom from '../assets/images/chapters/planet_doom.png';
import planet_earth from '../assets/images/chapters/planet_earth.png';
import pink_blur_logo_text from '../assets/images/chapters/pink_blur_logo_text.png';
import planet_void from '../assets/images/chapters/planet_void.png';
import planet_crazy from '../assets/images/chapters/planet_void_2.png';


const Chapters = () => {
    const navigate = useNavigate();

    const clearStorage = () => {
        localStorage.clear();
        console.log("Local storage cleared");
        // Optional: Add visual feedback
        alert("Game progress has been reset!");
        // Optional: Force page refresh to update UI
        window.location.reload();
    };

    // Add these new styles for the stars
    const starsContainerStyle = {
        position: 'absolute',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        zIndex: 0
    };

// Add this CSS animation to your global styles or as a style tag
    const starAnimationStyle = `
    @keyframes twinkle {
        0% { opacity: 0; }
        50% { opacity: 1; }
        100% { opacity: 0; }
    }
`;

    // Add this function to create stars
    const createStars = () => {
        const stars = [];
        for (let i = 0; i < 200; i++) { // Adjust number of stars
            const animationDuration = 3 + Math.random() * 4; // Random duration between 3-7s
            const starStyle = {
                position: 'absolute',
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                width: `${Math.random() * 3}px`,
                height: `${Math.random() * 3}px`,
                backgroundColor: '#ffffff',
                borderRadius: '50%',
                opacity: Math.random(),
                animation: `twinkle ${animationDuration}s infinite`,
                animationDelay: `${Math.random() * 5}s`
            };
            stars.push(<div key={i} style={starStyle} />);
        }
        return stars;
    };

    const containerStyle = {
        backgroundColor: '#19101a',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '2rem',
        paddingTop: '55px',
        position: 'relative', // Added to help position the gradients
        zIndex: 1
    };

    // New styles for the side gradients
    const leftGradientStyle = {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '300px', // Adjust width of gradient
        height: '100%',
        background: 'linear-gradient(90deg, rgba(255,182,193,0.25) 0%, rgba(25,16,26,0) 100%)',
        pointerEvents: 'none', // Ensures the gradient doesn't interfere with clicks
        zIndex: 1
    };

    const rightGradientStyle = {
        position: 'absolute',
        right: 0,
        top: 0,
        width: '300px', // Adjust width of gradient
        height: '100%',
        background: 'linear-gradient(270deg, rgba(255,182,193,0.25) 0%, rgba(25,16,26,0) 100%)',
        pointerEvents: 'none',
        zIndex: 1
    };

    const logoStyle = {
        width: '200px', // Adjust as needed
        marginBottom: '0', // Space between logo and chapters
        position: 'absolute',
        top: '40px',
        left: '40px'
    };

    const chapterTitleStyle = {
        color: '#ffffff',
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '1rem',
        textAlign: 'center',
        fontFamily: 'Arial',
        letterSpacing: '1px'
    };

    const chapterContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '0.5rem'
    };

    const chaptersRowStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '2rem',
        marginTop: '140px',
        zIndex: 1
    };

    const chapterStyle = {
        cursor: 'pointer',
        transition: 'transform 0.2s',
        width: '200px',
        opacity: 1
    };

    const doomStyle = {
        cursor: 'pointer',
        transition: 'transform 0.2s',
        width: '260px',
        opacity: 1,
        zIndex: 1
    };

    const voidStyle = {
        cursor: 'pointer',
        transition: 'transform 0.2s',
        width: '200px',
        opacity: 1,
        zIndex: 1
    };

    const disabledChapterStyle = {
        ...chapterStyle,
        opacity: 0.3,
        cursor: 'default',
        zIndex: 1
    };

    // Add this new style for the retro text
    const retroTextStyle = {
        color: '#ffffff',
        fontFamily: "'Press Start 2P', 'Courier New', monospace", // Retro gaming font
        fontSize: '24px',
        textShadow: '2px 2px 0px #ff69b4, -2px -2px 0px #4a4a4a',
        letterSpacing: '2px',
        textAlign: 'center',
        marginTop: '0', // Pushes the text to the bottom
        paddingBottom: '2rem', // Space from bottom of page
        animation: 'pulse 2s infinite',
        zIndex: 1
    };

    const retroTextStyleTitle = {
        color: '#ffffff',
        fontFamily: "'Press Start 2P', 'Courier New', monospace", // Retro gaming font
        fontSize: '24px',
        textShadow: '2px 2px 0px #ff69b4, -2px -2px 0px #4a4a4a',
        letterSpacing: '2px',
        textAlign: 'center',
        marginTop: '80px', // Pushes the text to the bottom
        animation: 'pulse 2s infinite',
        zIndex: 1
    };

    const handleChapter1Click = () => {
        navigate('/game');
    };

    const handleChapter2Click = () => {
        navigate('/game-chapter-two');
    };

    return (
        <div style={containerStyle}>
            <div style={starsContainerStyle}>
                {createStars()}
            </div>
            {/* Add the gradient divs */}
            <div style={leftGradientStyle}></div>
            <div style={rightGradientStyle}></div>


            <img
                src={pink_blur_logo_text}
                alt="Logo"
                style={logoStyle}
            />
            <div style={chaptersRowStyle}>
                <div style={chapterContainerStyle}>
                    <img
                        src={planet_doom}
                        alt="Chapter 1"
                        style={doomStyle}
                        onClick={handleChapter1Click}
                        onMouseOver={(e) => e.target.style.transform = 'scale(1.1)'}
                        onMouseOut={(e) => e.target.style.transform = 'scale(1)'}
                    />
                </div>
                <div style={chapterContainerStyle}>
                    <img
                        src={planet_void}
                        alt="Chapter 2"
                        style={voidStyle}
                        onClick={handleChapter2Click}
                        onMouseOver={(e) => e.target.style.transform = 'scale(1.1)'}
                        onMouseOut={(e) => e.target.style.transform = 'scale(1)'}

                    />
                </div>
                <div style={chapterContainerStyle}>
                    <img
                        src={planet_crazy}
                        alt="Chapter 3"
                        style={disabledChapterStyle}
                    />
                </div>
                <div style={chapterContainerStyle}>
                    <img
                        src={planet_earth}
                        alt="Chapter 4"
                        style={disabledChapterStyle}
                    />
                </div>
            </div>
            <p style={retroTextStyle}>Are you fast enough?</p>

            <button
                onClick={clearStorage}
                style={{
                    position: 'absolute',
                    top: '20px',
                    right: '20px',
                    padding: '10px 20px',
                    backgroundColor: 'pink',
                    color: 'black',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    fontWeight: 'bold'
                }}
            >
                Reset Game Progress
            </button>

        </div>
    );
};

export default Chapters;
