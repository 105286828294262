export const levelInstructions = [
    {
        "name": "0",
        "gameInstructions": "Welcome to the Void! The Pink Blur must battle through the empty wastes of space and time!"

    },
    {
        "name": "1",
        "gameInstructions": ""

    },
    {
        "name": "2",
        "gameInstructions": ""
    },
    {
        "name": "3",
        "gameInstructions": ""
    },
    {
        "name": "4",
        "gameInstructions": ""
    },
    {
        "name": "5",
        "gameInstructions": ""
    },
    {
        "name": "6",
        "gameInstructions": ""

    },
    {
        "name": "7",
        "gameInstructions": ""

    }
];

