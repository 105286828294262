export const levelInstructions = [
    {
        "name": "0",
        "gameInstructions": "This is the Pink Blur. \nYou can help her move with the keyboard arrows. Give it a try. Right, Left, Up and Down.                   Oh - you can collect the gold coins to spend in the Space Store between levels!"

    },
    {
        "name": "1",
        "gameInstructions": "Nice! All that matters to the Pink Blur is speed.\nYou finish each level by reaching a target speed. Try it!                             Oh and yes, there's some things that will get in your way ..."

    },
    {
        "name": "2",
        "gameInstructions": "It's kind of dark isn't it? Some say the Pink Blur existed before the stars. Some" +
            " say ... she went so fast that she made the stars!"

    },
    {
        "name": "3",
        "gameInstructions": "Oh, that's better!\nWith the stars though came other things ...  but use the SPACE BAR to fire her laser!         Excellent ... OK, training is done .... let's get to THE PLANET OF DOOM!"

    },
    {
        "name": "4",
        "gameInstructions": "One last thing we forgot! When you reach a speed of 30, you can FLY!"

    },
    {
        "name": "5",
        "gameInstructions": "Now it is simple - battle and dodge your way through!"

    },
    {
        "name": "6",
        "gameInstructions": ""

    },
    {
        "name": "7",
        "gameInstructions": ""

    }
];

